import React, { useLayoutEffect } from 'react';
import { Gap, DividerLine, Text, Colors } from 'advance-components';
import Container from '../components/container';

export default function DividerLineScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Divider Line',
    });
  }, []);

  return (
    <Container backgroundColor={Colors.neutral100}>
      <Text.Title>Strong</Text.Title>
      <Gap size='f32' height />
      <DividerLine theme='strong' />

      <Gap size='f80' height />

      <Text.Title>Medium</Text.Title>
      <Gap size='f32' height />
      <DividerLine />

      <Gap size='f80' height />

      <Text.Title>Light</Text.Title>
      <Gap size='f32' height />
      <DividerLine theme='light' />

      <Gap size='f56' height />
    </Container>
  );
}
