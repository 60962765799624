import React, { useLayoutEffect, Fragment } from 'react';
import { Gap, DividerLine, Text } from 'advance-components';
import Container from '../components/container';

export default function GapScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Gap',
    });
  }, []);

  return (
    <Container>
      {Object.keys(Gap.sizes).map((key) => (
        <Fragment key={key}>
          <Text.BodyBase>{Gap.sizes[key]}</Text.BodyBase>
          <Gap size={key} height />
        </Fragment>
      ))}
      <DividerLine />
    </Container>
  );
}
