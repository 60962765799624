import React, { useLayoutEffect } from 'react';
import { Platform } from 'react-native';
import { ContentCard, Gap, Text, DividerLine } from 'advance-components';
import useMobile from 'advance-components/src/hooks/use-mobile';
import Container from '../components/container';
import sampleContentCardImg from '../../assets/sample-content-card-img.png';

export default function ContentCardScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Content Card',
    });
  }, []);

  const mobile = useMobile();
  const getMaxWidth = Platform.select({
    web: (type) => (mobile ? '100%' : type === 'vertical' ? 300 : 500),
    default: () => '100%',
  });
  const contentCardProps = {
    imgSrc: sampleContentCardImg,
    tag: 'Tagging',
    header: 'This is the header',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    link: { label: 'Call to action', onPress: () => {} },
    maxWidth: getMaxWidth(),
    width: '100%',
  };
  const verticalMaxWidth = getMaxWidth('vertical');

  return (
    <Container>
      <Text.Title>Vertical</Text.Title>
      <Gap size='f16' height />
      <ContentCard.Vertical {...contentCardProps} maxWidth={verticalMaxWidth} />
      <Gap size='f16' height />

      <Text.Label>No tag</Text.Label>
      <Gap size='f8' height />
      <ContentCard.Vertical
        {...contentCardProps}
        maxWidth={verticalMaxWidth}
        tag=''
      />
      <Gap size='f16' height />

      <Text.Label>No CTA</Text.Label>
      <Gap size='f8' height />
      <ContentCard.Vertical
        {...contentCardProps}
        maxWidth={verticalMaxWidth}
        link={{}}
      />
      <Gap size='f16' height />

      <Text.Label>No tag and CTA</Text.Label>
      <Gap size='f8' height />
      <ContentCard.Vertical
        {...contentCardProps}
        maxWidth={verticalMaxWidth}
        tag=''
        link={{}}
      />

      <Gap size='f48' height />
      <DividerLine theme='strong' />
      <Gap size='f48' height />

      <Text.Title>Horizontal</Text.Title>
      <Gap size='f16' height />
      <ContentCard.Horizontal {...contentCardProps} />
      <Gap size='f16' height />

      <Text.Label>No tag</Text.Label>
      <Gap size='f8' height />
      <ContentCard.Horizontal {...contentCardProps} tag='' />
      <Gap size='f16' height />

      <Text.Label>No CTA</Text.Label>
      <Gap size='f8' height />
      <ContentCard.Horizontal {...contentCardProps} link={{}} />
      <Gap size='f16' height />

      <Text.Label>No tag and CTA</Text.Label>
      <Gap size='f8' height />
      <ContentCard.Horizontal {...contentCardProps} tag='' link={{}} />
      <Gap size='f48' height />
    </Container>
  );
}
