import React, { useLayoutEffect } from 'react';
import {
  Spacer,
  Breadcrumb,
  Icon,
  AdvanceDetailsHeader,
} from 'advance-components';

export default function AdvanceDetailsHeaderScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Advance Details Header',
    });
  }, []);

  return (
    <>
      <AdvanceDetailsHeader
        header={
          <Breadcrumb
            color='white'
            links={[
              { label: 'First Link', onPress: () => {} },
              { label: 'Second Link', onPress: () => {} },
            ]}
          >
            Text
          </Breadcrumb>
        }
        iconName='Medicine'
        iconFamily={Icon.AdvanceLineIcon}
        disbursementDate='Nov 25, 2016'
        referenceNumber='32144DA'
      >
        Medicine
      </AdvanceDetailsHeader>
      <Spacer size='f24' height />
      <AdvanceDetailsHeader
        simple
        iconName='Medicine'
        iconFamily={Icon.AdvanceLineIcon}
        disbursementDate='Nov 25, 2016'
        referenceNumber='32144DA'
      >
        Simple Mobile Header
      </AdvanceDetailsHeader>
    </>
  );
}
