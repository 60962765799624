import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components/native';
import { Spacer, Text, PinKeypad } from 'advance-components';
import Container from '../components/container';

const TextInput = styled.TextInput`
  border: 1px solid #ccc;
`;

export default function PinKeypadScreen({ navigation }) {
  const [sampleText1, setSampleText1] = useState('Sample Text 1');
  const [sampleText2, setSampleText2] = useState('Sample Text 2');
  const [sampleText3, setSampleText3] = useState('Sample Text 3');

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Pin Keypad',
    });
  }, []);

  return (
    <Container backgroundColor='white'>
      <Text.TitleBase>{sampleText1}</Text.TitleBase>
      <Spacer size='f24' height />
      <PinKeypad onChangeText={setSampleText1} />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <TextInput
        onChangeText={setSampleText2}
        value={sampleText2}
        keyboardType='default'
      />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <TextInput
        onChangeText={setSampleText3}
        value={sampleText3}
        keyboardType='number-pad'
      />
    </Container>
  );
}
