import React, { useLayoutEffect } from 'react';
import {
  Text,
  illustrations,
  PageHandler,
  Gap,
  DividerLine,
} from 'advance-components';
import Container from '../components/container';

export default function PageHandlerScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'PageHandler',
    });
  }, []);

  return (
    <Container>
      <PageHandler
        header='Header title'
        subHeader='Optional sub header content'
        imgSrc={illustrations.Waiting}
        leftButton={{
          icon: 'light-stroke',
          label: 'Button 1',
          onPress: () => {},
        }}
        rightButton={{
          icon: 'light-stroke',
          label: 'Button 2',
          onPress: () => {},
        }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
        cursus ornare turpis, in consectetur velit convallis sed. Sed in nibh
        sit amet sem sodales consequat. In consectetur elit turpis, a faucibus
        nibh porta non.
      </PageHandler>
      <Gap size='f48' height />
      <DividerLine />
      <Gap size='f48' height />
      <Text.Body weight='bold'>Without Sub-header</Text.Body>
      <Gap size='f24' height />
      <PageHandler
        header='Header title'
        imgSrc={illustrations.Warning}
        leftButton={{
          icon: 'light-stroke',
          label: 'Button 1',
          onPress: () => {},
        }}
        rightButton={{
          icon: 'light-stroke',
          label: 'Button 2',
          onPress: () => {},
        }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
        cursus ornare turpis, in consectetur velit convallis sed. Sed in nibh
        sit amet sem sodales consequat. In consectetur elit turpis, a faucibus
        nibh porta non.
      </PageHandler>
    </Container>
  );
}
