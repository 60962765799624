import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import { illustrations, SvgRenderer, Text } from 'advance-components';
import Container from '../components/container';

const Wrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const RendererContainer = styled.View`
  margin: 30px;
  align-items: center;
  gap: 8px;
`;

export default function IllustrationScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Illustrations',
    });
  }, []);

  return (
    <Container>
      <Wrapper>
        {Object.keys(illustrations).map((key, index) => (
          <RendererContainer key={index}>
            <SvgRenderer source={illustrations[key]} height={200} width={200} />
            <Text>{key}</Text>
          </RendererContainer>
        ))}
      </Wrapper>
    </Container>
  );
}
