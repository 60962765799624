import React, { useLayoutEffect } from 'react';
import { Navigation } from 'advance-components';
import useMobile from 'advance-components/src/hooks/use-mobile';
import ContentGroupScreen from './content-group-screen';
import DocumentLinkScreen from './document-link-screen';
import HeadingGroupScreen from './heading-group-screen';
import NotificationRowScreen from './notification-row-screen';
import SpacerScreen from './spacer-screen';
import StatusScreen from './status-screen';
import TypographyScreen from './typography-screen';

const HomeScreen = () => (
  <Navigation.Tab
    tabs={[
      { name: 'Advances', content: ContentGroupScreen },
      { name: 'Payments', content: HeadingGroupScreen },
    ]}
  />
);

const RequestScreen = () => (
  <Navigation.Tab
    tabs={[
      { name: 'Refer a Friend', content: StatusScreen },
      { name: 'Invited', content: TypographyScreen },
      { name: 'Rewards', content: NotificationRowScreen },
    ]}
  />
);

export default function NavigationScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Navigation',
    });
  }, []);

  const mobile = useMobile();

  return (
    <>
      {mobile && (
        <Navigation.Bottom
          tabs={{
            home: HomeScreen,
            request: RequestScreen,
            advances: DocumentLinkScreen,
            payments: SpacerScreen,
          }}
        />
      )}
    </>
  );
}
