import React, { useLayoutEffect } from 'react';
import {
  Spacer,
  DividerLine,
  Transaction,
  Icon,
  Text,
} from 'advance-components';
import Container from '../components/container';

export default function TranactionScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Transaction',
    });
  }, []);

  const completedTransactions = [
    {
      headline: 'Disbursement',
      subtext: '472ABJ-LIF',
      amount: 500000,
      amountSubtext: 'Online bank transfer',
      icon: 'arrow-up-right',
      iconFamily: Icon.Feather,
    },
    {
      headline: 'Payment',
      subtext: '472ABJ-LIF',
      amount: 1000000,
      amountSubtext: 'Online bank transfer',
      icon: 'arrow-down-left',
      iconFamily: Icon.Feather,
    },
    {
      headline: 'Disbursement',
      subtext: '472ABJ-LIF',
      amount: 1000000,
      amountSubtext: 'Online bank transfer',
      icon: 'arrow-up-right',
      iconFamily: Icon.Feather,
    },
  ];

  const onPress = () => {
    // eslint-disable-next-line no-alert
    alert('Transaction.Completed Transaction Item Pressed');
  };

  return (
    <Container>
      <Text.Body>Completed Transaction Item</Text.Body>
      <Spacer size='f24' height />
      <Transaction.Completed
        headline='Headline'
        subtext='Subtext'
        amountSubtext='Subtext'
        icon='appstore-o'
        iconFamily={Icon.AntDesign}
        onPress={onPress}
      />
      <Spacer size='f12' height />
      <Transaction.Completed
        headline='Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        subtext='Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        amount={10000000000000}
        amountSubtext='Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        icon='appstore-o'
        iconFamily={Icon.AntDesign}
        onPress={onPress}
      />
      <Spacer size='f12' height />
      {completedTransactions.map((transaction, i) => (
        <Transaction.Completed
          key={i}
          headline={transaction.headline}
          subtext={transaction.subtext}
          amount={transaction.amount}
          amountSubtext={transaction.amountSubtext}
          icon={transaction.icon}
          iconFamily={transaction.iconFamily}
          onPress={onPress}
        />
      ))}

      <Spacer size='f24' height />
      <Text.Body>Transaction - Old Design System</Text.Body>

      <Transaction.List
        list={[
          {
            icon: 'Medicine',
            amount: 'P1000',
            purpose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'disbursed',
            onPress: () => {},
          },
          {
            icon: 'Medicine',
            amount: 'P1000',
            purpose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'processing',
            onPress: () => {},
          },
          {
            icon: 'Medicine',
            amount: 'P1000',
            purpose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'none',
            onPress: () => {},
          },
          {
            icon: 'purpose icon',
            pupose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'none',
            onPress: () => {},
          },
        ]}
      />
      <Spacer size='f12' height />
      <DividerLine />
      <Spacer size='f12' height />
      <Transaction.List
        list={[
          {
            icon: 'Medicine',
            amount: 'P1000',
            purpose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'disbursed',
            onPress: () => {},
          },
          {
            icon: 'Medicine',
            amount: 'P1000',
            purpose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'processing',
            onPress: () => {},
          },
          {
            icon: 'Medicine',
            amount: 'P1000',
            purpose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'none',
            onPress: () => {},
          },
          {
            icon: 'purpose icon',
            pupose: 'Medicine',
            referenceNo: '420QIJQK69',
            status: 'none',
            onPress: () => {},
          },
        ]}
      />
      <Spacer size='f12' height />
      <DividerLine />
      <Spacer size='f12' height />
      <Transaction.Breakdown status='error' />
      <Spacer size='f12' height />
      <DividerLine />
      <Spacer size='f12' height />
      <Transaction.Breakdown status='success' />
    </Container>
  );
}
