import React, { useLayoutEffect } from 'react';
import {
  Overlay,
  AdvanceDetailsHeader,
  illustrations,
  SvgRenderer,
  Icon,
  Breadcrumb,
  Colors,
} from 'advance-components';
import { View } from 'react-native';

const styles = {
  container: {
    backgroundColor: Colors.white,
  },
};

export default function OverlayScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Overlay',
    });
  }, []);

  return (
    <>
      <AdvanceDetailsHeader
        header={
          <Breadcrumb
            color='white'
            links={[
              { label: 'First Link', onPress: () => {} },
              { label: 'Second Link', onPress: () => {} },
            ]}
          >
            Text
          </Breadcrumb>
        }
        iconName='Medicine'
        iconFamily={Icon.AdvanceLineIcon}
        disbursementDate='Nov 25, 2016'
        referenceNumber='32144DA'
      >
        Medicine
      </AdvanceDetailsHeader>
      <Overlay justifyContent='center'>
        <View style={styles.container}>
          <SvgRenderer
            source={illustrations.Congratulations}
            height={300}
            width={300}
          />
        </View>
      </Overlay>
    </>
  );
}
