import React, { useLayoutEffect } from 'react';
import { Spacer, AdvanceBreakdown } from 'advance-components';
import Container from '../components/container';

export default function BadgeScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Advance Breakdown',
    });
  }, []);

  const date1 = new Date('July 30, 2021');
  const date2 = new Date('August 15, 2021');

  return (
    <Container>
      <AdvanceBreakdown.Section
        type='default'
        amount={2550}
        principal={5000}
        fees={187}
        total={3890}
        deductionDates={[date1, date2]}
      />
      <Spacer size='f24' height />
      <AdvanceBreakdown.Section
        type='discount'
        amount={2593}
        discountedAmount={1945}
        principal={5000}
        fees={187}
        discount={1296}
        total={3890}
        deductionDates={[date1, date2]}
      />
      <Spacer size='d48' height />
      <AdvanceBreakdown.PaymentTerms
        type='default'
        deductions={[
          {
            amount: 2575,
            principal: 2500,
            fees: 75,
            date: date1,
          },
          {
            amount: 2575,
            principal: 2500,
            fees: 75,
            date: date2,
          },
        ]}
      />
      <Spacer size='f24' height />
      <AdvanceBreakdown.PaymentTerms
        type='discount'
        deductions={[
          {
            amount: 2593,
            discountedAmount: 1945,
            principal: 2500,
            fees: 75,
            date: date1,
          },
          {
            amount: 2593,
            discountedAmount: 1945,
            principal: 2500,
            fees: 75,
            date: date2,
          },
        ]}
      />
    </Container>
  );
}
