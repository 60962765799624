import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import { Checkbox, Gap } from 'advance-components';
import Container from '../components/container';

const StyledContainer = styled.View`
  margin-right: auto;
  align-items: flex-start;
  justify-content: center;
  padding: 40px;
`;

export default function CheckboxScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Checkbox',
    });
  }, []);

  return (
    <Container>
      <StyledContainer>
        <Checkbox />
        <Gap size='f40' height />
        <Checkbox isActive />
        <Gap size='f40' height />
        <Checkbox disabled />

        <Gap size='f56' height />

        <Checkbox label='Option' subLabel='Supporting text' />
        <Gap size='f40' height />
        <Checkbox label='Option' subLabel='Supporting text' isActive />
        <Gap size='f40' height />
        <Checkbox label='Option' subLabel='Supporting text' disabled />
      </StyledContainer>
    </Container>
  );
}
