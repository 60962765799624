import React, { useLayoutEffect } from 'react';
import { Spacer, Breadcrumb, Text, DividerLine } from 'advance-components';
import Container from '../components/container';

export default function BreadcrumbsScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Breadcrumbs',
    });
  }, []);

  return (
    <Container>
      <Text.BodyBold>Normal</Text.BodyBold>

      <Spacer size='f16' height />
      <DividerLine theme='light' />
      <Spacer size='f16' height />

      <Text.BodyBold>One link</Text.BodyBold>
      <Spacer size='f24' height />
      <Breadcrumb
        color='normal'
        links={[{ label: 'First Link', onPress: () => {} }]}
      >
        Text
      </Breadcrumb>

      <Spacer size='f16' height />
      <DividerLine theme='light' />
      <Spacer size='f16' height />

      <Text.BodyBold>Two links</Text.BodyBold>
      <Spacer size='f24' height />
      <Breadcrumb
        color='normal'
        links={[
          { label: 'First Link', onPress: () => {} },
          { label: 'Second Link', onPress: () => {} },
        ]}
      >
        Text
      </Breadcrumb>
      <Spacer size='f24' height />
      <Breadcrumb
        color='normal'
        expandable={false}
        links={[
          { label: 'First Link', onPress: () => {} },
          { label: 'Second Link', onPress: () => {}, compressed: true },
        ]}
      >
        Text
      </Breadcrumb>

      <Spacer size='f16' height />
      <DividerLine theme='light' />
      <Spacer size='f16' height />

      <Text.BodyBold>More than two links</Text.BodyBold>
      <Spacer size='f24' height />
      <Breadcrumb
        color='normal'
        links={[
          { label: 'First Link', onPress: () => {} },
          { label: 'Second Link', onPress: () => {} },
          { label: 'Third Link', onPress: () => {} },
          { label: 'Fourth Link', onPress: () => {} },
          { label: 'Fifth Link', onPress: () => {} },
          { label: 'Sixth Link', onPress: () => {} },
          { label: 'Seventh Link', onPress: () => {} },
        ]}
      >
        Text
      </Breadcrumb>
      <Spacer size='f24' height />
      <Breadcrumb
        color='normal'
        expandable={false}
        links={[
          { label: 'First Link', onPress: () => {} },
          { label: 'Second Link', onPress: () => {}, compressed: true },
          { label: 'Third Link', onPress: () => {}, compressed: true },
          { label: 'Fourth Link', onPress: () => {}, compressed: true },
          { label: 'Fifth Link', onPress: () => {}, compressed: true },
          { label: 'Sixth Link', onPress: () => {}, compressed: true },
          { label: 'Seventh Link', onPress: () => {}, compressed: true },
        ]}
      >
        Text
      </Breadcrumb>
      <Spacer size='f24' height />
      <Breadcrumb
        color='normal'
        expandable={false}
        links={[
          { label: 'First Link', onPress: () => {} },
          { label: 'Second Link', onPress: () => {}, compressed: true },
          { label: 'Third Link', onPress: () => {}, compressed: true },
          { label: 'Fourth Link', onPress: () => {}, compressed: true },
          { label: 'Fifth Link', onPress: () => {}, compressed: true },
          { label: 'Sixth Link', onPress: () => {}, compressed: true },
          { label: 'Seventh Link', onPress: () => {} },
        ]}
      >
        Text
      </Breadcrumb>
      <Spacer size='f24' height />
      <Breadcrumb
        color='normal'
        expandable={false}
        links={[
          { label: 'First Link', onPress: () => {} },
          { label: 'Second Link', onPress: () => {}, compressed: true },
          { label: 'Third Link', onPress: () => {}, compressed: true },
          { label: 'Fourth Link', onPress: () => {} },
          { label: 'Fifth Link', onPress: () => {}, compressed: true },
          { label: 'Sixth Link', onPress: () => {}, compressed: true },
          { label: 'Seventh Link', onPress: () => {} },
        ]}
      >
        Text
      </Breadcrumb>
      <Spacer size='f24' height />
      <Breadcrumb
        color='normal'
        expandable={false}
        links={[
          { label: 'First Link', onPress: () => {}, compressed: true },
          { label: 'Second Link', onPress: () => {}, compressed: true },
          { label: 'Third Link', onPress: () => {}, compressed: true },
          { label: 'Fourth Link', onPress: () => {}, compressed: true },
          { label: 'Fifth Link', onPress: () => {}, compressed: true },
          { label: 'Sixth Link', onPress: () => {}, compressed: true },
          { label: 'Seventh Link', onPress: () => {} },
        ]}
      >
        Text
      </Breadcrumb>
      <Spacer size='f24' height />
      <Breadcrumb
        color='normal'
        expandable={false}
        links={[
          { label: 'First Link', onPress: () => {}, compressed: true },
          { label: 'Second Link', onPress: () => {}, compressed: true },
          { label: 'Third Link', onPress: () => {}, compressed: true },
          { label: 'Fourth Link', onPress: () => {} },
          { label: 'Fifth Link', onPress: () => {}, compressed: true },
          { label: 'Sixth Link', onPress: () => {}, compressed: true },
          { label: 'Seventh Link', onPress: () => {}, compressed: true },
        ]}
      >
        Text
      </Breadcrumb>

      <Spacer size='f24' height />
      <DividerLine />
      <Spacer size='f16' height />

      <Text.BodyBold>White</Text.BodyBold>

      <Spacer size='f16' height />
      <DividerLine theme='light' />
      <Spacer size='f16' height />

      <Text.BodyBold>One link</Text.BodyBold>
      <Spacer size='f24' height />
      <Breadcrumb
        color='white'
        links={[{ label: 'First Link', onPress: () => {} }]}
      >
        Text
      </Breadcrumb>

      <Spacer size='f16' height />
      <DividerLine theme='light' />
      <Spacer size='f16' height />

      <Text.BodyBold>Two links</Text.BodyBold>
      <Spacer size='f24' height />
      <Breadcrumb
        color='white'
        links={[
          { label: 'First Link', onPress: () => {} },
          { label: 'Second Link', onPress: () => {} },
        ]}
      >
        Text
      </Breadcrumb>
      <Spacer size='f24' height />
      <Breadcrumb
        color='white'
        expandable={false}
        links={[
          { label: 'First Link', onPress: () => {} },
          { label: 'Second Link', onPress: () => {}, compressed: true },
        ]}
      >
        Text
      </Breadcrumb>
      <Spacer size='f24' height />
    </Container>
  );
}
