import React, { useLayoutEffect } from 'react';
import { Spacer, Requirement } from 'advance-components';
import Container from '../components/container';

export default function RequirementsScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Requirements',
    });
  }, []);

  return (
    <Container>
      <Requirement state='default'>8 or more characters</Requirement>
      <Spacer size='f12' height />
      <Requirement state='default'>
        Both uppercase and lowercase letters
      </Requirement>
      <Spacer size='f12' height />
      <Requirement state='default'>At least one (1) number</Requirement>
      <Spacer size='f24' height />
      <Requirement state='success'>8 or more characters</Requirement>
      <Spacer size='f12' height />
      <Requirement state='default'>
        Both uppercase and lowercase letters
      </Requirement>
      <Spacer size='f12' height />
      <Requirement state='default'>At least one (1) number</Requirement>
      <Spacer size='f24' height />
      <Requirement state='success'>8 or more characters</Requirement>
      <Spacer size='f12' height />
      <Requirement state='success'>
        Both uppercase and lowercase letters
      </Requirement>
      <Spacer size='f12' height />
      <Requirement state='default'>At least one (1) number</Requirement>
      <Spacer size='f24' height />
      <Requirement state='success'>8 or more characters</Requirement>
      <Spacer size='f12' height />
      <Requirement state='success'>
        Both uppercase and lowercase letters
      </Requirement>
      <Spacer size='f12' height />
      <Requirement state='success'>At least one (1) number</Requirement>
    </Container>
  );
}
