import React, { useLayoutEffect } from 'react';
import { useSnackbar, Text } from 'advance-components';
import Container from '../components/container';

export default function SnackbarScreen({ navigation }) {
  const snackbar = useSnackbar();

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Snackbar',
    });
  }, []);

  return (
    <Container>
      <Text.TitleBase
        onPress={() => {
          snackbar.show('Mobile number has been updated.', {
            hideOnPress: snackbar.hide,
          });
        }}
      >
        Press me to display Snackbar
      </Text.TitleBase>
    </Container>
  );
}
