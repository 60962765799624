import React, { useLayoutEffect } from 'react';
import { View } from 'react-native';
import { Spacer, Referral, Colors, DividerLine } from 'advance-components';
import Container from '../components/container';

const addDays = (initialDate, days) => {
  const date = new Date(initialDate);
  date.setDate(date.getDate() + days);

  return date;
};

const StyledDivider = () => {
  const style = {
    paddingLeft: 8,
    paddingRight: 8,
  };

  return (
    <View style={style}>
      <Spacer size='f16' height />
      <DividerLine />
      <Spacer size='f16' height />
    </View>
  );
};

export default function ReferralListItemScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Referral List Item',
    });
  }, []);

  const date1 = new Date();
  const date2 = addDays(date1, 30);
  const date3 = addDays(date2, 60);

  return (
    <Container>
      <Container backgroundColor={Colors.neutral100}>
        <Spacer size='f16' height />
        <Referral date={date1} status='verified'>
          John Doe
        </Referral>
        <StyledDivider />
        <Referral date={date2}>Lorem ipsum</Referral>
        <StyledDivider />
        <Referral date={date3} status='verifying'>
          Lorem ipsum dolor sit amet consectetur adipiscing elit
        </Referral>
        <Spacer size='f16' height />
      </Container>
    </Container>
  );
}
