import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import { Colors, TitleBar, Icon } from 'advance-components';

const View = styled.View`
  flex: 1;
  background-color: ${Colors.white};
`;

export default function TitleBarDarkScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <TitleBar
          theme='dark'
          title='Account related'
          border
          renderLeft={() => (
            <Icon.MaterialIcons
              size={24}
              color={Colors.white}
              name='arrow-back'
              onPress={() => {
                navigation.goBack();
              }}
            />
          )}
          renderRight={() => (
            <Icon.MaterialIcons
              size={24}
              color={Colors.white}
              name='help-outline'
            />
          )}
        />
      ),
    });
  }, []);

  return <View />;
}
