import React, { useLayoutEffect } from 'react';
import { Spacer, Text, DividerLine } from 'advance-components';
import Container from '../components/container';

export default function TypographyScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Typography',
    });
  }, []);

  return (
    <Container>
      <Text.DisplayLarge weight='bold'>Display Large - Bold</Text.DisplayLarge>
      <Text.DisplayLarge weight='semi'>Display Large - Semi</Text.DisplayLarge>
      <Text.DisplayLarge>Display Large - Base</Text.DisplayLarge>
      <Spacer size='f24' height />
      <Text.Display weight='bold'>Display Normal - Bold</Text.Display>
      <Text.Display weight='semi'>Display Normal - Semi</Text.Display>
      <Text.Display>Display Normal - Base</Text.Display>
      <Spacer size='f24' height />
      <Text.DisplaySmall weight='bold'>Display Small - Bold</Text.DisplaySmall>
      <Text.DisplaySmall weight='semi'>Display Small - Semi</Text.DisplaySmall>
      <Text.DisplaySmall>Display Small - Base</Text.DisplaySmall>

      <Spacer size='f24' height />

      <Text.HeaderLarge weight='bold'>Header Large - Bold</Text.HeaderLarge>
      <Text.HeaderLarge weight='semi'>Header Large - Semi</Text.HeaderLarge>
      <Text.HeaderLarge>Header Large - Base</Text.HeaderLarge>
      <Spacer size='f24' height />
      <Text.Header weight='bold'>Header Normal - Bold</Text.Header>
      <Text.Header weight='semi'>Header Normal - Semi</Text.Header>
      <Text.Header>Header Normal - Base</Text.Header>
      <Spacer size='f24' height />
      <Text.HeaderSmall weight='bold'>Header Small - Bold</Text.HeaderSmall>
      <Text.HeaderSmall weight='semi'>Header Small - Semi</Text.HeaderSmall>
      <Text.HeaderSmall>Header Small - Base</Text.HeaderSmall>

      <Spacer size='f24' height />

      <Text.TitleLarge weight='bold'>Title Large - Bold</Text.TitleLarge>
      <Text.TitleLarge weight='semi'>Title Large - Semi</Text.TitleLarge>
      <Text.TitleLarge>Title Large - Base</Text.TitleLarge>
      <Spacer size='f24' height />
      <Text.Title weight='bold'>Title Normal - Bold</Text.Title>
      <Text.Title weight='semi'>Title Normal - Semi</Text.Title>
      <Text.Title>Title Normal - Base</Text.Title>
      <Spacer size='f24' height />
      <Text.TitleSmall weight='bold'>Title Small - Bold</Text.TitleSmall>
      <Text.TitleSmall weight='semi'>Title Small - Semi</Text.TitleSmall>
      <Text.TitleSmall>Title Small - Base</Text.TitleSmall>

      <Spacer size='f24' height />

      <Text.BodyLarge weight='bold'>Body Large - Bold</Text.BodyLarge>
      <Text.BodyLarge weight='semi'>Body Large - Semi</Text.BodyLarge>
      <Text.BodyLarge>Body Large - Base</Text.BodyLarge>
      <Spacer size='f24' height />
      <Text.Body weight='bold'>Body Normal - Bold</Text.Body>
      <Text.Body weight='semi'>Body Normal - Semi</Text.Body>
      <Text.Body>Body Normal - Base</Text.Body>
      <Spacer size='f24' height />
      <Text.BodySmall weight='bold'>Body Small - Bold</Text.BodySmall>
      <Text.BodySmall weight='semi'>Body Small - Semi</Text.BodySmall>
      <Text.BodySmall>Body Small - Base</Text.BodySmall>

      <Spacer size='f24' height />

      <Text.LabelLarge weight='bold'>Label Large - Bold</Text.LabelLarge>
      <Text.LabelLarge weight='semi'>Label Large - Semi</Text.LabelLarge>
      <Text.LabelLarge>Label Large - Base</Text.LabelLarge>
      <Spacer size='f24' height />
      <Text.Label weight='bold'>Label Normal - Bold</Text.Label>
      <Text.Label weight='semi'>Label Normal - Semi</Text.Label>
      <Text.Label>Label Normal - Base</Text.Label>
      <Spacer size='f24' height />
      <Text.LabelSmall weight='bold'>Label Small - Bold</Text.LabelSmall>
      <Text.LabelSmall weight='semi'>Label Small - Semi</Text.LabelSmall>
      <Text.LabelSmall>Label Small - Base</Text.LabelSmall>

      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <DividerLine />
      <Spacer size='f24' height />
      <Text.TitleSubheader>Old Components</Text.TitleSubheader>
      <Spacer size='f24' height />
      <Spacer size='f24' height />

      <Text.DisplayH1>H1 Display</Text.DisplayH1>
      <Spacer size='f24' height />
      <Text.DisplayH2>H2 Display</Text.DisplayH2>
      <Spacer size='f24' height />
      <Text.DisplayH3>H3 Display</Text.DisplayH3>
      <Spacer size='f24' height />
      <Text.TitleH4>H4 Header</Text.TitleH4>
      <Spacer size='f24' height />
      <Text.TitleSubheader>Sub-Header</Text.TitleSubheader>
      <Spacer size='f24' height />
      <Text.TitleBase>Title</Text.TitleBase>
      <Spacer size='f24' height />
      <Text.BodyBold>Body Bold</Text.BodyBold>
      <Spacer size='f24' height />
      <Text.BodyBase>Body Text</Text.BodyBase>
      <Spacer size='f24' height />
      <Text.LabelBold>Label Bold</Text.LabelBold>
      <Spacer size='f24' height />
      <Text.LabelBase>Label</Text.LabelBase>
      <Spacer size='f24' height />
    </Container>
  );
}
