import React, { useLayoutEffect } from 'react';
import { Spacer, Instructions } from 'advance-components';
import Container from '../components/container';
import capture from '../../assets/capture.png';

export default function InstructionsScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Instructions',
    });
  }, []);

  return (
    <Container>
      <Instructions.Page mobileNumber='+639165958974' onPress={() => {}} />
      <Spacer size='d80' height />
      <Instructions.Capture
        image={capture}
        documentName='Certificate of Employment'
      />
      <Spacer size='d80' height />
      <Instructions.Password
        links={{
          forgotPassword: () => {},
          signUp: () => {},
        }}
      />
    </Container>
  );
}
