import React, { useLayoutEffect, useState } from 'react';
import { Text, LoginPin, Spacer } from 'advance-components';
import Container from '../components/container';

export default function LoginPinScreen({ navigation }) {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Login Pin',
    });
  }, []);

  return (
    <Container>
      <LoginPin
        value={value}
        onChangeText={setValue}
        cellCount={4}
        secureTextEntry
        error={error}
      />
      <Spacer size='f24' height />
      <Text.TitleBase onPress={() => setError(!error)}>
        Toggle Error
      </Text.TitleBase>
    </Container>
  );
}
