import React, { useLayoutEffect } from 'react';
import { View } from 'react-native';
import { Text, Spacer, Colors } from 'advance-components';
import styled from 'styled-components/native';
import Container from '../components/container';
import Row from '../components/row';

const BoxContainer = styled.View`
  height: 40px;
  width: 40px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Box = ({ name, backgroundColor }) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  return (
    <View style={containerStyle}>
      <Text.LabelBase>{name}</Text.LabelBase>
      <BoxContainer backgroundColor={backgroundColor} />
    </View>
  );
};

export default function ColorsScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Colors',
    });
  }, []);

  return (
    <Container>
      <Row>
        <Box name='primary10' backgroundColor={Colors.primary10} />
        <Box name='primary20' backgroundColor={Colors.primary20} />
        <Box name='primary30' backgroundColor={Colors.primary30} />
        <Box name='primary40' backgroundColor={Colors.primary40} />
        <Box name='primary50' backgroundColor={Colors.primary50} />
        <Box name='primary60' backgroundColor={Colors.primary60} />
        <Box name='primary70' backgroundColor={Colors.primary70} />
        <Box name='primary80' backgroundColor={Colors.primary80} />
        <Box name='primary90' backgroundColor={Colors.primary90} />
        <Box name='primary95' backgroundColor={Colors.primary95} />
        <Box name='primary98' backgroundColor={Colors.primary98} />
      </Row>
      <Spacer size='f24' height />
      <Row>
        <Box name='secondary10' backgroundColor={Colors.secondary10} />
        <Box name='secondary20' backgroundColor={Colors.secondary20} />
        <Box name='secondary30' backgroundColor={Colors.secondary30} />
        <Box name='secondary40' backgroundColor={Colors.secondary40} />
        <Box name='secondary50' backgroundColor={Colors.secondary50} />
        <Box name='secondary60' backgroundColor={Colors.secondary60} />
        <Box name='secondary70' backgroundColor={Colors.secondary70} />
        <Box name='secondary80' backgroundColor={Colors.secondary80} />
        <Box name='secondary90' backgroundColor={Colors.secondary90} />
        <Box name='secondary95' backgroundColor={Colors.secondary95} />
        <Box name='secondary98' backgroundColor={Colors.secondary98} />
      </Row>
      <Spacer size='f24' height />
      <Row>
        <Box name='tertiary10' backgroundColor={Colors.tertiary10} />
        <Box name='tertiary20' backgroundColor={Colors.tertiary20} />
        <Box name='tertiary30' backgroundColor={Colors.tertiary30} />
        <Box name='tertiary40' backgroundColor={Colors.tertiary40} />
        <Box name='tertiary50' backgroundColor={Colors.tertiary50} />
        <Box name='tertiary60' backgroundColor={Colors.tertiary60} />
        <Box name='tertiary70' backgroundColor={Colors.tertiary70} />
        <Box name='tertiary80' backgroundColor={Colors.tertiary80} />
        <Box name='tertiary90' backgroundColor={Colors.tertiary90} />
        <Box name='tertiary95' backgroundColor={Colors.tertiary95} />
        <Box name='tertiary98' backgroundColor={Colors.tertiary98} />
      </Row>
      <Spacer size='f24' height />
      <Row>
        <Box name='positive10' backgroundColor={Colors.positive10} />
        <Box name='positive20' backgroundColor={Colors.positive20} />
        <Box name='positive30' backgroundColor={Colors.positive30} />
        <Box name='positive40' backgroundColor={Colors.positive40} />
        <Box name='positive50' backgroundColor={Colors.positive50} />
        <Box name='positive60' backgroundColor={Colors.positive60} />
        <Box name='positive70' backgroundColor={Colors.positive70} />
        <Box name='positive80' backgroundColor={Colors.positive80} />
        <Box name='positive90' backgroundColor={Colors.positive90} />
        <Box name='positive95' backgroundColor={Colors.positive95} />
        <Box name='positive98' backgroundColor={Colors.positive98} />
      </Row>
      <Spacer size='f24' height />
      <Row>
        <Box name='warning10' backgroundColor={Colors.warning10} />
        <Box name='warning20' backgroundColor={Colors.warning20} />
        <Box name='warning30' backgroundColor={Colors.warning30} />
        <Box name='warning40' backgroundColor={Colors.warning40} />
        <Box name='warning50' backgroundColor={Colors.warning50} />
        <Box name='warning60' backgroundColor={Colors.warning60} />
        <Box name='warning70' backgroundColor={Colors.warning70} />
        <Box name='warning80' backgroundColor={Colors.warning80} />
        <Box name='warning90' backgroundColor={Colors.warning90} />
        <Box name='warning95' backgroundColor={Colors.warning95} />
        <Box name='warning98' backgroundColor={Colors.warning98} />
      </Row>
      <Spacer size='f24' height />
      <Row>
        <Box name='negative10' backgroundColor={Colors.negative10} />
        <Box name='negative20' backgroundColor={Colors.negative20} />
        <Box name='negative30' backgroundColor={Colors.negative30} />
        <Box name='negative40' backgroundColor={Colors.negative40} />
        <Box name='negative50' backgroundColor={Colors.negative50} />
        <Box name='negative60' backgroundColor={Colors.negative60} />
        <Box name='negative70' backgroundColor={Colors.negative70} />
        <Box name='negative80' backgroundColor={Colors.negative80} />
        <Box name='negative90' backgroundColor={Colors.negative90} />
        <Box name='negative95' backgroundColor={Colors.negative95} />
        <Box name='negative98' backgroundColor={Colors.negative98} />
      </Row>
      <Spacer size='f24' height />
      <Row>
        <Box name='neutral10' backgroundColor={Colors.neutral10} />
        <Box name='neutral20' backgroundColor={Colors.neutral20} />
        <Box name='neutral30' backgroundColor={Colors.neutral30} />
        <Box name='neutral40' backgroundColor={Colors.neutral40} />
        <Box name='neutral50' backgroundColor={Colors.neutral50} />
        <Box name='neutral60' backgroundColor={Colors.neutral60} />
        <Box name='neutral70' backgroundColor={Colors.neutral70} />
        <Box name='neutral80' backgroundColor={Colors.neutral80} />
        <Box name='neutral90' backgroundColor={Colors.neutral90} />
        <Box name='neutral95' backgroundColor={Colors.neutral95} />
        <Box name='neutral98' backgroundColor={Colors.neutral98} />
        <Box name='neutral100' backgroundColor={Colors.neutral100} />
      </Row>
    </Container>
  );
}
