import React, { useLayoutEffect } from 'react';
import { Spacer, Badge } from 'advance-components';
import Container from '../components/container';

export default function BadgeScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Badge',
    });
  }, []);

  return (
    <Container>
      {/* Notify Badges */}
      <Badge type='notify' variant='alert'>
        Alert
      </Badge>
      <Spacer size='f12' height />
      <Badge type='notify' variant='warning'>
        Warning
      </Badge>
      <Spacer size='f12' height />
      <Badge type='notify' variant='neutral'>
        Neutral
      </Badge>
      <Spacer size='f24' height />

      {/* Dot Badges */}
      <Badge type='dot' variant='alert' />
      <Spacer size='f12' height />
      <Badge type='dot' variant='warning' />
      <Spacer size='f12' height />
      <Badge type='dot' variant='neutral' />
      <Spacer size='f24' height />

      {/* Count Badges */}
      <Badge type='count'>5</Badge>
      <Spacer size='f12' height />
      <Badge type='count'>99</Badge>
      <Spacer size='f12' height />
      <Badge type='count'>100</Badge>
      <Spacer size='f24' height />
    </Container>
  );
}
