import React, { useLayoutEffect, useState } from 'react';
import { Colors, BottomSheet, Button } from 'advance-components';
import { View, StyleSheet } from 'react-native';

export default function ToggleScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Bottom Sheet',
    });
  }, []);

  const [active, setActive] = useState(false);

  return (
    <View style={styles.mainView}>
      <Button onPress={() => setActive(true)}>Open bottom sheet</Button>
      <BottomSheet
        active={active}
        activeSetter={setActive}
        headline='Headline'
        height='auto'
        showHandle
      >
        <View style={styles.sampleView} />
      </BottomSheet>
    </View>
  );
}

const styles = StyleSheet.create({
  mainView: {
    flex: 1,
  },
  sampleView: {
    height: 142,
    backgroundColor: Colors.neutral98,
  },
});
