import React, { useLayoutEffect } from 'react';
import { DividerLine, Spacer, HeadingGroup } from 'advance-components';
import Container from '../components/container';

export default function HeadingGroupScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Heading Group',
    });
  }, []);

  return (
    <Container>
      <HeadingGroup title='Hi Hermaine!'>
        Help us get to know you better! Please providing the following details:
      </HeadingGroup>
      <Spacer size='f24' height />
      <DividerLine />
      <Spacer size='f24' height />
      <HeadingGroup
        type='article'
        title='Important Advisory: System Upgrade Today'
      >
        July 3, 2020 12:00 PM
      </HeadingGroup>
      <Spacer size='f24' height />
      <DividerLine />
      <Spacer size='f24' height />
      <HeadingGroup type='form' title='Form Section Heading' />
      <Spacer size='f24' height />
      <DividerLine />
      <Spacer size='f24' height />
      <HeadingGroup type='details' title='Details Section Heading (Web App)' />
      <Spacer size='f24' height />
      <DividerLine />
      <Spacer size='f24' height />
      <HeadingGroup
        type='mobile-app-details'
        title='Details Section Heading (Mobile App)'
      />
    </Container>
  );
}
