import React, { useLayoutEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components/native';
import { Text, Pagination, Gap, DividerLine } from 'advance-components';
import Container from '../components/container';

const StyledContainer = styled.View`
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const usePagination = (totalPages, initialPage = 1) => {
  const [page, setPage] = useState({
    currentPage: initialPage,
    prevPage: initialPage > 1 ? initialPage - 1 : null,
    nextPage: initialPage < totalPages ? initialPage + 1 : null,
  });

  const onFirstPage = () => {
    setPage({
      currentPage: 1,
      prevPage: null,
      nextPage: totalPages > 1 ? 2 : null,
    });
  };

  const onPreviousPage = () => {
    setPage((prevPage) => ({
      currentPage: prevPage.currentPage - 1,
      prevPage: prevPage.currentPage === 2 ? null : prevPage.currentPage - 1,
      nextPage:
        prevPage.currentPage <= totalPages ? prevPage.currentPage + 1 : null,
    }));
  };

  const onNextPage = () => {
    setPage((prevPage) => ({
      currentPage: prevPage.currentPage + 1,
      prevPage: prevPage.currentPage,
      nextPage:
        prevPage.currentPage < totalPages - 1 ? prevPage.currentPage + 2 : null,
    }));
  };

  const onLastPage = () => {
    setPage({
      currentPage: totalPages,
      prevPage: totalPages > 1 ? totalPages - 1 : null,
      nextPage: null,
    });
  };

  const jumpToPage = (pageNumber) => {
    const number = _.parseInt(pageNumber);
    if (!_.isNaN(number) && number >= 1 && number <= totalPages) {
      setPage({
        currentPage: number,
        prevPage: number === 1 ? null : number - 1,
        nextPage: number === totalPages ? null : number + 1,
      });
    }
  };

  return {
    page,
    onFirstPage,
    onPreviousPage,
    onNextPage,
    onLastPage,
    jumpToPage,
  };
};

export default function PaginationScreen({ navigation }) {
  const basic1 = usePagination(3, 1);
  const basic2 = usePagination(5, 1);
  const basic3 = usePagination(20, 1);
  const basic4 = usePagination(20, 5);
  const basic5 = usePagination(20, 10);
  const basic6 = usePagination(20, 15);
  const basic7 = usePagination(20, 20);
  const complex = usePagination(100);
  const slider = usePagination(5);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Pagination',
    });
  }, []);

  return (
    <Container>
      <StyledContainer>
        <Text.BodyBold>Pagination</Text.BodyBold>
        <Gap size='f24' height />
        <Text.BodyBase weight>Less than 5 pages</Text.BodyBase>
        <Pagination
          currentPage={basic1.page.currentPage}
          prevPage={basic1.page.prevPage}
          nextPage={basic1.page.nextPage}
          onFirstPage={basic1.onFirstPage}
          onPreviousPage={basic1.onPreviousPage}
          onNextPage={basic1.onNextPage}
          onLastPage={basic1.onLastPage}
          jumpToPage={(page) => basic1.jumpToPage(page)}
          totalPages={3}
        />

        <Gap size='f24' height />

        <Text.BodyBase>5 pages</Text.BodyBase>
        <Pagination
          currentPage={basic2.page.currentPage}
          prevPage={basic2.page.prevPage}
          nextPage={basic2.page.nextPage}
          onFirstPage={basic2.onFirstPage}
          onPreviousPage={basic2.onPreviousPage}
          onNextPage={basic2.onNextPage}
          onLastPage={basic2.onLastPage}
          jumpToPage={(page) => basic2.jumpToPage(page)}
          totalPages={5}
        />

        <Gap size='f24' height />
        <Text.BodyBold>20 pages</Text.BodyBold>
        <Gap size='f8' height />
        <Text.BodyBase>Page: 1</Text.BodyBase>
        <Pagination
          currentPage={basic3.page.currentPage}
          prevPage={basic3.page.prevPage}
          nextPage={basic3.page.nextPage}
          onFirstPage={basic3.onFirstPage}
          onPreviousPage={basic3.onPreviousPage}
          onNextPage={basic3.onNextPage}
          onLastPage={basic3.onLastPage}
          jumpToPage={(page) => basic3.jumpToPage(page)}
          totalPages={20}
        />

        <Gap size='f16' height />
        <Text.BodyBase>Page: 5</Text.BodyBase>
        <Pagination
          currentPage={basic4.page.currentPage}
          prevPage={basic4.page.prevPage}
          nextPage={basic4.page.nextPage}
          onFirstPage={basic4.onFirstPage}
          onPreviousPage={basic4.onPreviousPage}
          onNextPage={basic4.onNextPage}
          onLastPage={basic4.onLastPage}
          jumpToPage={(page) => basic4.jumpToPage(page)}
          totalPages={20}
        />

        <Gap size='f16' height />
        <Text.BodyBase>Page: 10</Text.BodyBase>

        <Pagination
          currentPage={basic5.page.currentPage}
          prevPage={basic5.page.prevPage}
          nextPage={basic5.page.nextPage}
          onFirstPage={basic5.onFirstPage}
          onPreviousPage={basic5.onPreviousPage}
          onNextPage={basic5.onNextPage}
          onLastPage={basic5.onLastPage}
          jumpToPage={(page) => basic5.jumpToPage(page)}
          totalPages={20}
        />

        <Gap size='f16' height />
        <Text.BodyBase>Page: 15</Text.BodyBase>
        <Pagination
          currentPage={basic6.page.currentPage}
          prevPage={basic6.page.prevPage}
          nextPage={basic6.page.nextPage}
          onFirstPage={basic6.onFirstPage}
          onPreviousPage={basic6.onPreviousPage}
          onNextPage={basic6.onNextPage}
          onLastPage={basic6.onLastPage}
          jumpToPage={(page) => basic6.jumpToPage(page)}
          totalPages={20}
        />

        <Gap size='f16' height />
        <Text.BodyBase>Page: 20</Text.BodyBase>
        <Pagination
          currentPage={basic7.page.currentPage}
          prevPage={basic7.page.prevPage}
          nextPage={basic7.page.nextPage}
          onFirstPage={basic7.onFirstPage}
          onPreviousPage={basic7.onPreviousPage}
          onNextPage={basic7.onNextPage}
          onLastPage={basic7.onLastPage}
          jumpToPage={(page) => basic7.jumpToPage(page)}
          totalPages={20}
        />

        <Gap size='f176' height />
        <Gap size='f176' height />
        <DividerLine />
        <Gap size='f80' height />
        <Text.BodyBold>Complex Pagination</Text.BodyBold>
        <Gap size='f24' height />

        <Pagination.Complex
          currentPage={complex.page.currentPage}
          prevPage={complex.page.prevPage}
          nextPage={complex.page.nextPage}
          totalPages={20}
          onFirstPage={complex.onFirstPage}
          onPreviousPage={complex.onPreviousPage}
          onNextPage={complex.onNextPage}
          onLastPage={complex.onLastPage}
          jumpToPage={(page) => complex.jumpToPage(page)}
        />

        <Gap size='f80' height />
        <Text.BodyBold>Slider</Text.BodyBold>
        <Gap size='f24' height />

        <Pagination.Slider
          currentPage={slider.page.currentPage}
          prevPage={slider.page.prevPage}
          nextPage={slider.page.nextPage}
          onFirstPage={slider.onFirstPage}
          onPreviousPage={slider.onPreviousPage}
          onNextPage={slider.onNextPage}
          onLastPage={slider.onLastPage}
          jumpToPage={(page) => slider.jumpToPage(page)}
        />
      </StyledContainer>
    </Container>
  );
}
