import React, { useLayoutEffect } from 'react';
import { Gap, ProgressBar, Colors } from 'advance-components';
import styled from 'styled-components/native';
import useMobile from 'advance-components/src/hooks/use-mobile';
import Container from '../components/container';

const StyledGroup = styled.View`
  background-color: ${Colors.neutral100};
  ${(props) => !props.mobile && { maxWidth: 500 }};
  margin: 20px;
  padding: 20px;
`;

export default function ProgressBarScreen({ navigation }) {
  const mobile = useMobile();

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Progress Bar',
    });
  }, []);

  return (
    <Container>
      <StyledGroup mobile={mobile}>
        <ProgressBar progress={0} leftLabel='Label' rightLabel='Support' />
        <Gap size='f24' height />
        <ProgressBar
          progress={25}
          leftLabel='Lorem ipsum dolor sit amet'
          rightLabel='Lorem ipsum'
        />
        <Gap size='f24' height />
        <ProgressBar
          progress={50}
          leftLabel='Lorem ipsum'
          rightLabel='Lorem ipsum dolor sit amet'
        />
        <Gap size='f24' height />
        <ProgressBar progress={75} leftLabel='Label' rightLabel='Support' />
        <Gap size='f24' height />
        <ProgressBar progress={100} leftLabel='Label' rightLabel='Support' />

        <Gap size='f56' height />

        <ProgressBar progress={0} />
        <Gap size='f24' height />
        <ProgressBar progress={25} />
        <Gap size='f24' height />
        <ProgressBar progress={50} />
        <Gap size='f24' height />
        <ProgressBar progress={75} />
        <Gap size='f24' height />
        <ProgressBar progress={100} />
      </StyledGroup>
    </Container>
  );
}
