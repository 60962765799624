import React, { useLayoutEffect } from 'react';
import { Spacer, ContentGroup, Colors, Icon } from 'advance-components';
import Container from '../components/container';

export default function ContentGroupScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Content Group',
    });
  }, []);

  const successIcon = () => (
    <Icon.MaterialIcons
      name='check-circle'
      size={16}
      color={Colors.success400}
    />
  );

  return (
    <Container>
      <ContentGroup.Page
        title='Title'
        primaryBtn={{ label: 'Primary' }}
        secondaryBtn={{ label: 'Tertiary' }}
      >
        Body
      </ContentGroup.Page>
      <Spacer size='d48' height />
      <ContentGroup.Empty button={{ label: 'Text Button' }}>
        Empty state copy
      </ContentGroup.Empty>
      <Spacer size='d48' height />
      <ContentGroup.UserDetails
        name='Rolando Luis Macsaet'
        company='Advance Tech Lending Inc.'
      />
      <Spacer size='d48' height />
      <ContentGroup.UserDetails
        name='Rolando Luis Macsaet'
        company='Advance Tech Lending Inc.'
        icon={successIcon}
      />
      <Spacer size='d48' height />
      <ContentGroup.UserDetails
        name='Rolando Luis Macsaet'
        company='Advance Tech Lending Inc.'
        theme='light'
      />
      <Spacer size='d48' height />
      <ContentGroup.UserDetails
        name='Rolando Luis Macsaet'
        company='Advance Tech Lending Inc.'
        icon={successIcon}
        theme='light'
      />
    </Container>
  );
}
