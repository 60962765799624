import React, { useLayoutEffect, useState } from 'react';
import { View } from 'react-native';
import { Spacer, Widget } from 'advance-components';
import Container from '../components/container';

export default function CreditLineWidgetsScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Credit Line Widgets',
    });
  }, []);

  const [value1, setValue1] = useState(15000);

  const viewStyle = {
    maxWidth: '417px',
    padding: '24px',
  };

  return (
    <Container>
      <View style={viewStyle}>
        <Widget.AvailableCredit
          usedCredit={15000}
          creditLimit={30000}
          buttons={[
            { name: 'request', onPress: () => {}, disabled: false },
            { name: 'pay', onPress: () => {}, disabled: false },
          ]}
        />
        <Spacer size='f24' height />
        <Widget.AvailableCredit
          usedCredit={5000}
          creditLimit={30000}
          buttons={[{ name: 'request', onPress: () => {}, disabled: false }]}
          showInfoIcon
        />
        <Spacer size='d48' height />
        <Widget.RequestAmount
          type='edit'
          value={value1}
          valueSetter={setValue1}
          minCreditLimit={1000}
          maxCreditLimit={30000}
          step={1000}
        />
      </View>
    </Container>
  );
}
