import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import { Spacer, Colors, Wizard } from 'advance-components';
import Container from '../components/container';

const StyledView = styled.View`
  background-color: ${Colors.white};
`;

export default function WizardHeadersAndFootersScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Wizard Headers and Footers',
    });
  }, []);

  return (
    <Container>
      <StyledView>
        <Wizard.Header
          title='Title'
          description='Description'
          step={1}
          totalSteps={8}
        />
      </StyledView>
      <Spacer size='f24' height />
      <StyledView>
        <Wizard.Footer
          back={{ label: 'Back', onPress: () => {}, disabled: false }}
          next={{
            label: 'Save and Continue',
            onPress: () => {},
            disabled: false,
          }}
        />
      </StyledView>
    </Container>
  );
}
