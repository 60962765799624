import React, { useLayoutEffect } from 'react';
import { Colors, SystemBar } from 'advance-components';

export default function SystemBarLightScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'System Bar Light',
      headerStyle: {
        backgroundColor: Colors.white,
      },
    });
  }, []);

  return <SystemBar />;
}
