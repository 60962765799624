import React, { useLayoutEffect } from 'react';
import { View } from 'react-native';
import { Text, Spacer, Colors, Layout } from 'advance-components';
import styled from 'styled-components/native';
import Container from '../components/container';

const BoxContainer = styled.View`
  height: 80px;
  width: 160px;
  margin: 10px;
  background-color: ${Colors.primary98};
  border-width: ${(props) => props.border};
  border-color: ${Colors.primary50};
  border-radius: ${(props) => props.radius};
`;

const FlexRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const Box = ({ name, border, radius }) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  return (
    <View style={containerStyle}>
      <Text.LabelBase>{name}</Text.LabelBase>
      <BoxContainer border={border} radius={radius} />
    </View>
  );
};

export default function LayoutTokensScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Layout Tokens',
    });
  }, []);

  const { Radius, Border } = Layout;

  return (
    <Container>
      <Text.LabelBold>Border</Text.LabelBold>
      <FlexRow>
        <Box name='none' border={Border.none} />
        <Box name='skinny' border={Border.skinny} />
        <Box name='lean' border={Border.lean} />
        <Box name='chonky' border={Border.chonky} />
      </FlexRow>
      <Spacer size='f24' height />
      <Text.LabelBold>Corner Radius</Text.LabelBold>
      <FlexRow>
        <Box name='none' border={Border.skinny} radius={Radius.none} />
        <Box name='radius4' border={Border.skinny} radius={Radius.radius4} />
        <Box name='radius8' border={Border.skinny} radius={Radius.radius8} />
        <Box name='radius16' border={Border.skinny} radius={Radius.radius16} />
        <Box name='radius24' border={Border.skinny} radius={Radius.radius24} />
        <Box name='radius32' border={Border.skinny} radius={Radius.radius32} />
        <Box name='radius40' border={Border.skinny} radius={Radius.radius40} />
        <Box name='radius56' border={Border.skinny} radius={Radius.radius56} />
        <Box name='full' border={Border.skinny} radius={Radius.full} />
      </FlexRow>
    </Container>
  );
}
