import React, { useLayoutEffect, useState } from 'react';
import { Spacer, Widget } from 'advance-components';
import Container from '../components/container';

export default function CreditLineWidgetsScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Credit Line Widgets',
    });
  }, []);

  const [value1, setValue1] = useState(1000);
  const [value2, setValue2] = useState(15000);
  const [value3, setValue3] = useState(30000);

  return (
    <Container>
      <Widget.AvailableCredit
        usedCredit={5000}
        creditLimit={30000}
        buttons={[{ name: 'request', onPress: () => {}, disabled: false }]}
      />
      <Spacer size='f24' height />
      <Widget.AvailableCredit
        usedCredit={10000}
        creditLimit={30000}
        buttons={[
          { name: 'request', onPress: () => {}, disabled: false },
          { name: 'pay', onPress: () => {}, disabled: false },
        ]}
      />
      <Spacer size='f24' height />
      <Widget.AvailableCredit
        usedCredit={15000}
        creditLimit={30000}
        buttons={[
          { name: 'request', onPress: () => {}, disabled: false },
          { name: 'pay', onPress: () => {}, disabled: false },
          { name: 'more', onPress: () => {}, disabled: false },
        ]}
      />
      <Spacer size='d64' height />
      <Widget.RequestAmount
        type='default'
        minCreditLimit={1000}
        maxCreditLimit={30000}
        step={1000}
      />
      <Spacer size='d48' height />
      <Widget.RequestAmount
        type='edit'
        value={15000}
        minCreditLimit={1000}
        maxCreditLimit={30000}
        step={1000}
      />
      <Spacer size='d48' height />
      <Widget.RequestAmount
        type='minMax'
        value={25000}
        minCreditLimit={1000}
        maxCreditLimit={30000}
        step={1000}
      />
      <Spacer size='d64' height />
      <Widget.Slider
        value={value1}
        minAmount={100}
        maxAmount={30000}
        step={100}
        onChange={setValue1}
      />
      <Spacer size='f24' height />
      <Widget.Slider
        value={value2}
        minAmount={100}
        maxAmount={30000}
        step={100}
        onChange={setValue2}
      />
      <Spacer size='f24' height />
      <Widget.Slider
        value={value3}
        minAmount={100}
        maxAmount={30000}
        step={100}
        onChange={setValue3}
      />
    </Container>
  );
}
