import React, { useLayoutEffect, useState } from 'react';
import { Text, LoginPin, Spacer, Colors } from 'advance-components';
import Container from '../components/container';

export default function LoginPinScreen({ navigation }) {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Login Pin',
    });
  }, []);

  return (
    <Container>
      <Text.Body weight='bold' onPress={() => setError(!error)}>
        Toggle Error
      </Text.Body>
      <Spacer size='f24' height />
      <LoginPin
        value={value}
        onChangeText={setValue}
        cellCount={4}
        secureTextEntry
        error={error}
        label='Enter your PIN'
        errorMessage='Error'
      />
      <Spacer size='f24' height />
      <Text.Body>None</Text.Body>
      <Text.Body weight='semi' color={Colors.neutral80}>
        ●
      </Text.Body>
      <Spacer size='f24' height />
      <Text.Body>Hidden</Text.Body>
      <Text.Body weight='semi' color={Colors.neutral10}>
        ●
      </Text.Body>
      <Spacer size='f24' height />
      <Text.Body>Shown</Text.Body>
      <Text.Body weight='semi' color={Colors.neutral10}>
        1
      </Text.Body>
    </Container>
  );
}
