import React, { useLayoutEffect } from 'react';
import { Colors, SystemBar } from 'advance-components';

export default function SystemBarLightScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'System Bar Light',
      headerStyle: {
        backgroundColor: Colors.neutral100,
      },
      headerLeftContainerStyle: {
        paddingLeft: 24,
      },
      headingRightContainerStyle: {
        paddingRight: 24,
      },
    });
  }, []);

  return <SystemBar />;
}
