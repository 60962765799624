import React, { useLayoutEffect } from 'react';
import { Spacer, NavigationListItem } from 'advance-components';
import Container from '../components/container';

export default function NavigationListItemScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Navigation List Item',
    });
  }, []);

  return (
    <Container>
      <NavigationListItem
        onPress={() => navigation.navigate('DividerLineScreen')}
      >
        Personal Information
      </NavigationListItem>
      <Spacer size='f24' height />
      <NavigationListItem
        items={[
          {
            label: 'Uploaded Documents',
            onPress: () => navigation.navigate('DocumentLinkScreen'),
          },
          {
            label: 'Employment Details',
            onPress: () => navigation.navigate('BadgeScreen'),
          },
          {
            label: 'Bank Account Details',
            onPress: () => navigation.navigate('BreakdownRowScreen'),
          },
          {
            label: 'Login and Security',
            onPress: () => navigation.navigate('AlertScreen'),
          },
          {
            label: 'Notifications',
            onPress: () => navigation.navigate('IconScreen'),
          },
        ]}
      >
        Account
      </NavigationListItem>
    </Container>
  );
}
