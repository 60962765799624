import React, { useLayoutEffect } from 'react';
import { DocumentLink, Gap, Text } from 'advance-components';
import Container from '../components/container';

export default function DocumentLinkScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Document Link',
    });
  }, []);

  const onPressReupload = () => {
    // eslint-disable-next-line no-alert
    alert('REUPLOAD');
  };

  const onPressDelete = () => {
    // eslint-disable-next-line no-alert
    alert('DELETE');
  };

  const onPressPreview = () => {
    // eslint-disable-next-line no-alert
    alert('PREVIEW');
  };

  const onPressDownload = () => {
    // eslint-disable-next-line no-alert
    alert('DOWNLOAD');
  };

  const onPressCancel = () => {
    // eslint-disable-next-line no-alert
    alert('CANCEL');
  };

  return (
    <Container>
      <Text.Body weight='bold'>Uploading</Text.Body>
      <Gap size='f12' height />
      <DocumentLink
        name='File name'
        status='uploading'
        progress={50}
        onCancel={onPressCancel}
      />
      <Gap size='f48' height />

      <Text.Body weight='bold'>Error upload</Text.Body>
      <Gap size='f12' height />
      <DocumentLink
        name='File name'
        status='error'
        errorMessage='Subtext'
        onReupload={onPressReupload}
        onDelete={onPressDelete}
      />
      <Gap size='f48' height />

      <Text.Body weight='bold'>Uploaded</Text.Body>
      <Gap size='f12' height />
      <DocumentLink
        name='File name uploaded'
        status='uploaded'
        description='Subtext'
        onPreview={onPressPreview}
        onDownload={onPressDownload}
        onDelete={onPressDelete}
      />
      <Gap size='f48' height />

      <Text.Body weight='bold'>Label data pair</Text.Body>
      <Gap size='f12' height />
      <DocumentLink
        name='File name uploaded'
        description='Subtext'
        onPreview={onPressPreview}
        onDownload={onPressDownload}
        onDelete={onPressDelete}
      />
    </Container>
  );
}
