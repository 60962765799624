import React, { useLayoutEffect } from 'react';
import { Spacer, ProgressHeader } from 'advance-components';
import Container from '../components/container';

export default function ProgressBarScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Progress Header',
    });
  }, []);

  return (
    <Container>
      <ProgressHeader title='Uploading Documents' progress={40} />
      <Spacer size='f24' height />
      <ProgressHeader title='Uploading Documents' />
    </Container>
  );
}
