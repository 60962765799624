import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import { Spacer, Toggle } from 'advance-components';
import Container from '../components/container';

const StyledRow = styled.View`
  flex-direction: row;
`;

export default function ToggleScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Toggle',
    });
  }, []);

  return (
    <Container>
      <StyledRow>
        <Toggle onToggle={() => {}} />
        <Spacer size='f24' width />
        <Toggle active onToggle={() => {}} />
      </StyledRow>
      <Spacer size='f24' height />
      <StyledRow>
        <Toggle disabled />
        <Spacer size='f24' width />
        <Toggle active disabled />
      </StyledRow>
    </Container>
  );
}
