import React, { useLayoutEffect } from 'react';
import { Spacer, DividerLine } from 'advance-components';
import Container from '../components/container';

export default function DividerLineScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Divider Line',
    });
  }, []);

  return (
    <Container>
      <DividerLine />
      <Spacer size='f24' height />
      <DividerLine />
      <Spacer size='f24' height />
      <DividerLine />
      <Spacer size='f24' height />
      <DividerLine theme='light' />
      <Spacer size='f24' height />
      <DividerLine theme='light' />
      <Spacer size='f24' height />
      <DividerLine theme='light' />
    </Container>
  );
}
