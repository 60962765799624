import React, { useLayoutEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components/native';
import { Text, Pagination, Gap } from 'advance-components';
import Container from '../components/container';

const StyledContainer = styled.View`
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const usePagination = (totalPages) => {
  const initialPage = 1;
  const [page, setPage] = useState({
    currentPage: initialPage,
    prevPage: null,
    nextPage: initialPage < totalPages ? initialPage + 1 : null,
  });

  const onFirstPage = () => {
    setPage({
      currentPage: 1,
      prevPage: null,
      nextPage: totalPages > 1 ? 2 : null,
    });
  };

  const onPreviousPage = () => {
    setPage((prevPage) => ({
      currentPage: prevPage.currentPage - 1,
      prevPage: prevPage.currentPage === 2 ? null : prevPage.currentPage - 1,
      nextPage:
        prevPage.currentPage <= totalPages ? prevPage.currentPage + 1 : null,
    }));
  };

  const onNextPage = () => {
    setPage((prevPage) => ({
      currentPage: prevPage.currentPage + 1,
      prevPage: prevPage.currentPage,
      nextPage:
        prevPage.currentPage < totalPages - 1 ? prevPage.currentPage + 2 : null,
    }));
  };

  const onLastPage = () => {
    setPage({
      currentPage: totalPages,
      prevPage: totalPages > 1 ? totalPages - 1 : null,
      nextPage: null,
    });
  };

  const jumpToPage = (pageNumber) => {
    const number = _.parseInt(pageNumber);
    if (!_.isNaN(number) && number >= 1 && number <= totalPages) {
      setPage({
        currentPage: number,
        prevPage: number === 1 ? null : number - 1,
        nextPage: number === totalPages ? null : number + 1,
      });
    }
  };

  return {
    page,
    onFirstPage,
    onPreviousPage,
    onNextPage,
    onLastPage,
    jumpToPage,
  };
};

export default function PaginationScreen({ navigation }) {
  const complex = usePagination(100);
  const basic = usePagination(5);
  const slider = usePagination(5);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Pagination',
    });
  }, []);

  return (
    <Container>
      <StyledContainer>
        <Text.BodyBold>Basic Pagination</Text.BodyBold>
        <Gap size='f24' height />

        <Pagination
          currentPage={basic.page.currentPage}
          prevPage={basic.page.prevPage}
          nextPage={basic.page.nextPage}
          onFirstPage={basic.onFirstPage}
          onPreviousPage={basic.onPreviousPage}
          onNextPage={basic.onNextPage}
          onLastPage={basic.onLastPage}
          jumpToPage={(page) => basic.jumpToPage(page)}
        />

        <Gap size='f80' height />
        <Text.BodyBold>Complex Pagination</Text.BodyBold>
        <Gap size='f24' height />

        <Pagination.Complex
          currentPage={complex.page.currentPage}
          prevPage={complex.page.prevPage}
          nextPage={complex.page.nextPage}
          totalPages={100}
          onFirstPage={complex.onFirstPage}
          onPreviousPage={complex.onPreviousPage}
          onNextPage={complex.onNextPage}
          onLastPage={complex.onLastPage}
          jumpToPage={(page) => complex.jumpToPage(page)}
        />

        <Gap size='f80' height />
        <Text.BodyBold>Slider</Text.BodyBold>
        <Gap size='f24' height />

        <Pagination.Slider
          currentPage={slider.page.currentPage}
          prevPage={slider.page.prevPage}
          nextPage={slider.page.nextPage}
          onFirstPage={slider.onFirstPage}
          onPreviousPage={slider.onPreviousPage}
          onNextPage={slider.onNextPage}
          onLastPage={slider.onLastPage}
          jumpToPage={(page) => slider.jumpToPage(page)}
        />
      </StyledContainer>
    </Container>
  );
}
