import React, { useLayoutEffect } from 'react';
import {
  Spacer,
  DividerLine,
  illustrations,
  TextGroup,
} from 'advance-components';
import Container from '../components/container';

export default function TextGroupScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Text Group',
    });
  }, []);

  return (
    <Container>
      <TextGroup.Simple button={{ label: 'Request Advance' }}>
        You don’t have any active advances.
      </TextGroup.Simple>
      <Spacer size='f24' height />
      <DividerLine />
      <Spacer size='f24' height />
      <TextGroup.List
        title='Finding your account number:'
        button={{ label: 'Got it' }}
        list={[
          'On your statement account',
          'Call your bank',
          'Visit your local branch',
        ]}
      >
        Ways to find our your account number:
      </TextGroup.List>
      <Spacer size='f24' height />
      <DividerLine />
      <Spacer size='f24' height />
      <TextGroup.Illustration
        title='Can’t find what you’re looking for?'
        button={{ label: 'Contact Us' }}
        illustration={{
          source: illustrations.ContactUs,
          height: 257,
          width: 257,
          right: 25,
          bottom: -57,
        }}
      />
    </Container>
  );
}
