import React, { useLayoutEffect } from 'react';
import {
  useSnackbar,
  Text,
  Button,
  Icon,
  Alert,
  Spacer,
} from 'advance-components';
import styled from 'styled-components/native';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const StyledSnackbar = styled.View`
  min-width: 350px;
`;

export default function SnackbarScreen({ navigation }) {
  const snackbar = useSnackbar();

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Snackbar',
    });
  }, []);

  return (
    <Container>
      <Text.Label>Information</Text.Label>
      <Button
        size='xs'
        iconFamily={Icon.AdvanceIcons}
        icon='information-solid'
        onPress={() => {
          snackbar.show('Body content', {
            hideOnPress: snackbar.hide,
            snackbarType: 'information',
            buttonText: 'Action button',
            // eslint-disable-next-line no-alert
            buttonAction: () => alert('BUTTON PRESSED'),
          });
        }}
      >
        Press me to display information toast
      </Button>
      <Button
        type='secondary'
        size='xs'
        iconFamily={Icon.AdvanceIcons}
        icon='information-solid'
        onPress={() => {
          snackbar.show(
            'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.',
            {
              hideOnPress: snackbar.hide,
              snackbarType: 'information',
              buttonText: 'Action button',
              // eslint-disable-next-line no-alert
              buttonAction: () => alert('BUTTON PRESSED'),
            },
          );
        }}
      >
        Press me to display long information toast
      </Button>

      <Spacer height='f8' />
      <Text.Label>Success</Text.Label>
      <Button
        size='xs'
        iconFamily={Icon.AdvanceIcons}
        icon='correct-solid'
        onPress={() => {
          snackbar.show('Body content', {
            hideOnPress: snackbar.hide,
            snackbarType: 'success',
            buttonText: 'Action button',
            // eslint-disable-next-line no-alert
            buttonAction: () => alert('BUTTON PRESSED'),
          });
        }}
      >
        Press me to display success toast
      </Button>
      <Button
        type='secondary'
        size='xs'
        iconFamily={Icon.AdvanceIcons}
        icon='correct-solid'
        onPress={() => {
          snackbar.show(
            'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.',
            {
              hideOnPress: snackbar.hide,
              snackbarType: 'success',
              buttonText: 'Action button',
              // eslint-disable-next-line no-alert
              buttonAction: () => alert('BUTTON PRESSED'),
            },
          );
        }}
      >
        Press me to display long success toast
      </Button>

      <Spacer height='f8' />
      <Text.Label>Warning</Text.Label>
      <Button
        size='xs'
        iconFamily={Icon.AdvanceIcons}
        icon='error-solid'
        onPress={() => {
          snackbar.show('Body content', {
            hideOnPress: snackbar.hide,
            snackbarType: 'warning',
            buttonText: 'Action button',
            // eslint-disable-next-line no-alert
            buttonAction: () => alert('BUTTON PRESSED'),
          });
        }}
      >
        Press me to display warning toast
      </Button>
      <Button
        type='secondary'
        size='xs'
        iconFamily={Icon.AdvanceIcons}
        icon='error-solid'
        onPress={() => {
          snackbar.show(
            'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.',
            {
              hideOnPress: snackbar.hide,
              snackbarType: 'warning',
              buttonText: 'Action button',
              // eslint-disable-next-line no-alert
              buttonAction: () => alert('BUTTON PRESSED'),
            },
          );
        }}
      >
        Press me to display long warning toast
      </Button>

      <Spacer height='f8' />
      <Text.Label>Alert</Text.Label>
      <Button
        size='xs'
        iconFamily={Icon.AdvanceIcons}
        icon='warning-solid'
        onPress={() => {
          snackbar.show('Body content', {
            hideOnPress: snackbar.hide,
            snackbarType: 'alert',
            buttonText: 'Action button',
            // eslint-disable-next-line no-alert
            buttonAction: () => alert('BUTTON PRESSED'),
          });
        }}
      >
        Press me to display alert toast
      </Button>
      <Button
        type='secondary'
        size='xs'
        iconFamily={Icon.AdvanceIcons}
        icon='warning-solid'
        onPress={() => {
          snackbar.show(
            'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.',
            {
              hideOnPress: snackbar.hide,
              snackbarType: 'alert',
              buttonText: 'Action button',
              // eslint-disable-next-line no-alert
              buttonAction: () => alert('BUTTON PRESSED'),
            },
          );
        }}
      >
        Press me to display long alert toast
      </Button>

      <Spacer height='f8' />
      <Text.Label>Node / Component</Text.Label>
      <Button
        size='xs'
        type='hollow'
        onPress={() => {
          snackbar.show(
            <StyledSnackbar>
              <Alert.Simple type='information'>
                Display message here.
              </Alert.Simple>
            </StyledSnackbar>,
            {
              hideOnPress: snackbar.hide,
            },
          );
        }}
      >
        Press me to display default node/component toast
      </Button>
    </Container>
  );
}
