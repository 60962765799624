import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import { Gap, ValidationIndicator, Colors } from 'advance-components';
import useMobile from 'advance-components/src/hooks/use-mobile';
import Container from '../components/container';

const StyledGroup = styled.View`
  ${(props) => !props.mobile && { maxWidth: 500 }}
  background-color: ${Colors.neutral100};
  margin: 20px;
  padding: 20px;
`;

export default function ValidationIndicatorScreen({ navigation }) {
  const mobile = useMobile();

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Validation Indicator',
    });
  }, []);

  return (
    <Container>
      <StyledGroup mobile={mobile}>
        <ValidationIndicator>Lorem ipsum dolor sit amet</ValidationIndicator>
        <Gap size='f24' height />
        <ValidationIndicator state='success'>
          Lorem ipsum dolor sit amet
        </ValidationIndicator>
        <Gap size='f24' height />
        <ValidationIndicator state='error'>
          Lorem ipsum dolor sit amet
        </ValidationIndicator>
        <Gap size='f56' height />
        <ValidationIndicator>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </ValidationIndicator>
        <Gap size='f24' height />
        <ValidationIndicator state='success'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </ValidationIndicator>
        <Gap size='f24' height />
        <ValidationIndicator state='error'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </ValidationIndicator>
      </StyledGroup>
    </Container>
  );
}
