import React, { useLayoutEffect } from 'react';
import { Spacer, Referrals } from 'advance-components';
import Container from '../components/container';

export default function ReferralsScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Referrals',
    });
  }, []);

  const today = new Date();

  return (
    <Container>
      <Referrals date={today} status='verified'>
        Name
      </Referrals>
      <Spacer size='f24' height />
      <Referrals date={today} status='signed-up'>
        Name
      </Referrals>
      <Spacer size='f24' height />
      <Referrals date={today} status='verification-in-progress'>
        Name
      </Referrals>
    </Container>
  );
}
