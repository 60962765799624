import React, { useLayoutEffect, useState } from 'react';
import { Pressable } from 'react-native';
import { Gap, Text, Tooltip } from 'advance-components';
import Container from '../components/container';

export default function TooltipScreen({ navigation }) {
  const [showCtaTooltip1, setShowCtaTooltip1] = useState(false);
  const [showCtaTooltip2, setShowCtaTooltip2] = useState(false);
  const [showCtaTooltip3, setShowCtaTooltip3] = useState(false);
  const [showCtaTooltip4, setShowCtaTooltip4] = useState(false);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Tooltip',
    });
  }, []);

  return (
    <Container>
      <Gap size='f176' height />
      <Tooltip
        visible={showCtaTooltip1}
        title='Text'
        content='Body content'
        onClose={() => setShowCtaTooltip1(false)}
        placement='top'
      >
        <Pressable onPress={() => setShowCtaTooltip1(true)}>
          <Text.Title align='center'>Click for top tooltip</Text.Title>
        </Pressable>
      </Tooltip>
      <Gap size='f24' height />
      <Tooltip
        visible={showCtaTooltip2}
        title='Lorem ipsum'
        content='Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        onClose={() => setShowCtaTooltip2(false)}
      >
        <Pressable onPress={() => setShowCtaTooltip2(true)}>
          <Text.Title align='center'>Click for bottom tooltip</Text.Title>
        </Pressable>
      </Tooltip>
      <Gap size='f24' height />
      <Tooltip
        visible={showCtaTooltip3}
        title='Lorem ipsum'
        content='Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        onClose={() => setShowCtaTooltip3(false)}
        width={200}
        placement='top'
      >
        <Pressable onPress={() => setShowCtaTooltip3(true)}>
          <Text.Title align='center'>
            Click for top tooltip - custom width
          </Text.Title>
        </Pressable>
      </Tooltip>
      <Gap size='f24' height />
      <Tooltip
        visible={showCtaTooltip4}
        title='Lorem ipsum'
        content='Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        onClose={() => setShowCtaTooltip4(false)}
        width={200}
      >
        <Pressable onPress={() => setShowCtaTooltip4(true)}>
          <Text.Title align='center'>
            Click for bottom tooltip - custom width
          </Text.Title>
        </Pressable>
      </Tooltip>
    </Container>
  );
}
