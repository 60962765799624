import React, { useLayoutEffect } from 'react';
import { Spacer, DividerLine, Text } from 'advance-components';
import Container from '../components/container';

export default function SpacerScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Spacer',
    });
  }, []);

  return (
    <Container>
      <Text.BodyBase>Desktop 96 / Mobile 80</Text.BodyBase>
      <Spacer size='d96' height />
      <Text.BodyBase>Desktop 80 / Mobile 64</Text.BodyBase>
      <Spacer size='d80' height />
      <Text.BodyBase>Desktop 64 / Mobile 56</Text.BodyBase>
      <Spacer size='d64' height />
      <Text.BodyBase>Desktop 56 / Mobile 48</Text.BodyBase>
      <Spacer size='d56' height />
      <Text.BodyBase>Desktop 48 / Mobile 40</Text.BodyBase>
      <Spacer size='d48' height />
      <Text.BodyBase>Desktop 40 / Mobile 32</Text.BodyBase>
      <Spacer size='d40' height />
      <Text.BodyBase>Desktop 32 / Mobile 24</Text.BodyBase>
      <Spacer size='d32' height />
      <Text.BodyBase>Desktop 24 / Mobile 20</Text.BodyBase>
      <Spacer size='d24' height />
      <Text.BodyBase>Desktop 16 / Mobile 12</Text.BodyBase>
      <Spacer size='d16' height />
      <Text.BodyBase>Fixed 24</Text.BodyBase>
      <Spacer size='f24' height />
      <Text.BodyBase>Fixed 20</Text.BodyBase>
      <Spacer size='f20' height />
      <Text.BodyBase>Fixed 16</Text.BodyBase>
      <Spacer size='f16' height />
      <Text.BodyBase>Fixed 12</Text.BodyBase>
      <Spacer size='f12' height />
      <Text.BodyBase>Fixed 8</Text.BodyBase>
      <Spacer size='f8' height />
      <Text.BodyBase>Fixed 6</Text.BodyBase>
      <Spacer size='f6' height />
      <Text.BodyBase>Fixed 4</Text.BodyBase>
      <Spacer size='f4' height />
      <Text.BodyBase>Fixed 2</Text.BodyBase>
      <Spacer size='f2' height />
      <DividerLine />
    </Container>
  );
}
