import React, { useLayoutEffect } from 'react';
import { Spacer, Icon, Colors, HighlightedContent } from 'advance-components';
import Container from '../components/container';

export default function HighlightedContentScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Highlighted Content',
    });
  }, []);

  return (
    <Container>
      <HighlightedContent.Referral>Referral</HighlightedContent.Referral>
      <Spacer size='f24' height />
      <HighlightedContent type='default' label='Label' buttonName='change'>
        Default
      </HighlightedContent>
      <Spacer size='f24' height />
      <HighlightedContent
        type='with-icon'
        label='Label'
        buttonName='change'
        iconName='ticket-alt'
        iconFamily={Icon.FontAwesome5}
        iconColor={Colors.mint400}
      >
        With Icon
      </HighlightedContent>
    </Container>
  );
}
