import React, { useLayoutEffect, useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { Spacer, Icon, Colors, Text, Tooltip } from 'advance-components';
import Container from '../components/container';

export default function TooltipScreen({ navigation }) {
  const [showCtaTooltipBottom, setShowCtaTooltipBottom] = useState(false);
  const [showCtaTooltipTop, setShowCtaTooltipTop] = useState(false);
  const [showCtaTooltipNoIcon, setShowCtaTooltipNoIcon] = useState(false);
  const [showCtaTooltipTitle, setShowCtaTooltipTitle] = useState(false);
  const [showCtaTooltipBottomLeft, setShowCtaTooltipBottomLeft] =
    useState(false);
  const [showCtaTooltipTopLeft, setShowCtaTooltipTopLeft] = useState(false);
  const [showCtaTooltipBottomRight, setShowCtaTooltipBottomRight] =
    useState(false);
  const [showCtaTooltipTopRight, setShowCtaTooltipTopRight] = useState(false);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Tooltip',
    });
  }, []);

  return (
    <Container>
      <Spacer size='f24' height />
      <Tooltip
        visible={showCtaTooltipBottom}
        content='We are waiting for the confirmation.'
        onClose={() => setShowCtaTooltipBottom(false)}
        renderIcon={() => (
          <Icon.MaterialIcons
            name='info'
            color={Colors.advanceBlue300}
            size={20}
          />
        )}
        showChildInTooltip={false}
      >
        <Pressable
          onPress={() => {
            setShowCtaTooltipBottom(true);
          }}
        >
          <Text.TitleBase style={styles.textContainer}>
            Click for bottom tooltip
          </Text.TitleBase>
        </Pressable>
      </Tooltip>
      <Spacer size='f24' height />
      <Tooltip
        visible={showCtaTooltipTop}
        content='We are waiting for the confirmation.'
        onClose={() => setShowCtaTooltipTop(false)}
        verticalPlacement='top'
        renderIcon={() => (
          <Icon.MaterialIcons
            name='info'
            color={Colors.advanceBlue300}
            size={20}
          />
        )}
        showChildInTooltip={false}
      >
        <Pressable
          onPress={() => {
            setShowCtaTooltipTop(true);
          }}
        >
          <Text.TitleBase style={styles.textContainer}>
            Click for top tooltip
          </Text.TitleBase>
        </Pressable>
      </Tooltip>
      <Spacer size='f24' height />
      <Tooltip
        visible={showCtaTooltipNoIcon}
        content='We are waiting for the confirmation.'
        onClose={() => setShowCtaTooltipNoIcon(false)}
        showChildInTooltip={false}
      >
        <Pressable
          onPress={() => {
            setShowCtaTooltipNoIcon(true);
          }}
        >
          <Text.TitleBase style={styles.textContainer}>
            Click for no icon tooltip
          </Text.TitleBase>
        </Pressable>
      </Tooltip>
      <Spacer size='f24' height />
      <Tooltip
        visible={showCtaTooltipTitle}
        content='We are waiting for the confirmation.'
        title='Title'
        renderIcon={() => (
          <Icon.MaterialIcons
            name='info'
            color={Colors.advanceBlue300}
            size={20}
          />
        )}
        onClose={() => setShowCtaTooltipTitle(false)}
        showChildInTooltip={false}
      >
        <Pressable
          onPress={() => {
            setShowCtaTooltipTitle(true);
          }}
        >
          <Text.TitleBase style={styles.textContainer}>
            Click for tooltip with title
          </Text.TitleBase>
        </Pressable>
      </Tooltip>
      <Spacer size='f24' height />
      <Tooltip
        visible={showCtaTooltipBottomLeft}
        content='We are waiting for the confirmation.'
        horizontalPlacement='left'
        onClose={() => setShowCtaTooltipBottomLeft(false)}
        renderIcon={() => (
          <Icon.MaterialIcons
            name='info'
            color={Colors.advanceBlue300}
            size={20}
          />
        )}
        showChildInTooltip={false}
      >
        <Pressable
          onPress={() => {
            setShowCtaTooltipBottomLeft(true);
          }}
        >
          <Text.TitleBase style={styles.textContainer}>
            Click for bottom left tooltip
          </Text.TitleBase>
        </Pressable>
      </Tooltip>
      <Spacer size='f24' height />
      <Tooltip
        visible={showCtaTooltipTopLeft}
        content='We are waiting for the confirmation.'
        onClose={() => setShowCtaTooltipTopLeft(false)}
        verticalPlacement='top'
        horizontalPlacement='left'
        renderIcon={() => (
          <Icon.MaterialIcons
            name='info'
            color={Colors.advanceBlue300}
            size={20}
          />
        )}
        showChildInTooltip={false}
      >
        <Pressable
          onPress={() => {
            setShowCtaTooltipTopLeft(true);
          }}
        >
          <Text.TitleBase style={styles.textContainer}>
            Click for top left tooltip
          </Text.TitleBase>
        </Pressable>
      </Tooltip>
      <Spacer size='f24' height />
      <Tooltip
        visible={showCtaTooltipBottomRight}
        content='We are waiting for the confirmation.'
        onClose={() => setShowCtaTooltipBottomRight(false)}
        horizontalPlacement='right'
        renderIcon={() => (
          <Icon.MaterialIcons
            name='info'
            color={Colors.advanceBlue300}
            size={20}
          />
        )}
        showChildInTooltip={false}
      >
        <Pressable
          onPress={() => {
            setShowCtaTooltipBottomRight(true);
          }}
        >
          <Text.TitleBase style={styles.textContainer}>
            Click for bottom right tooltip
          </Text.TitleBase>
        </Pressable>
      </Tooltip>
      <Spacer size='f24' height />
      <Tooltip
        visible={showCtaTooltipTopRight}
        content='We are waiting for the confirmation.'
        onClose={() => setShowCtaTooltipTopRight(false)}
        verticalPlacement='top'
        horizontalPlacement='right'
        renderIcon={() => (
          <Icon.MaterialIcons
            name='info'
            color={Colors.advanceBlue300}
            size={20}
          />
        )}
        showChildInTooltip={false}
      >
        <Pressable
          onPress={() => {
            setShowCtaTooltipTopRight(true);
          }}
        >
          <Text.TitleBase style={styles.textContainer}>
            Click for top right tooltip
          </Text.TitleBase>
        </Pressable>
      </Tooltip>
    </Container>
  );
}

const styles = StyleSheet.create({
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
