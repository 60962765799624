import React, { useLayoutEffect } from 'react';
import { Spacer, NotificationRow } from 'advance-components';
import Container from '../components/container';

export default function NotificationRowScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Notification Row',
    });
  }, []);

  const oneDay = 86400000;
  const today = new Date();
  const yesterday = new Date(today - oneDay);
  const beforeYesterday = new Date(yesterday - oneDay);
  const thisYear = today.getFullYear();
  const lastYear = new Date(new Date().setFullYear(thisYear - 1));

  return (
    <Container>
      <NotificationRow type='active' title='Unread/Active' date={today}>
        Today
      </NotificationRow>
      <Spacer size='f24' height />
      <NotificationRow type='default' title='Read/Default' date={yesterday}>
        Yesterday
      </NotificationRow>
      <Spacer size='f24' height />
      <NotificationRow
        type='active'
        title='Unread/Active'
        date={beforeYesterday}
      >
        Older than yesterday but within the current year
      </NotificationRow>
      <Spacer size='f24' height />
      <NotificationRow type='default' title='Read/Default' date={lastYear}>
        Older than the current year
      </NotificationRow>
    </Container>
  );
}
