import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import { Button, Colors, PageHandler } from 'advance-components';
import Container from '../components/container';

const StyledContainer = styled.View`
  background-color: ${Colors.white};
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export default function PageHandlerScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'PageHandler',
    });
  }, []);

  return (
    <Container>
      <StyledContainer>
        <PageHandler
          title='title'
          body='body'
          button={{ label: 'button', onPress: () => {} }}
        >
          <Button.Text>Default</Button.Text>
        </PageHandler>
      </StyledContainer>
    </Container>
  );
}
