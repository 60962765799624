import React, { useLayoutEffect } from 'react';
import { Spacer, Voucher } from 'advance-components';
import Container from '../components/container';

export default function VoucherScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Voucher',
    });
  }, []);

  const today = new Date();

  return (
    <Container>
      <Voucher date={today} useCode={() => navigation.goBack()}>
        50% Discount
      </Voucher>
      <Spacer size='f24' height />
      <Voucher date={today} applied>
        50% Discount
      </Voucher>
      <Spacer size='f24' height />
      <Voucher date={today} past>
        50% Discount
      </Voucher>
      <Spacer size='f24' height />
      <Voucher date={today} past label='USED'>
        50% Discount
      </Voucher>
      <Spacer size='f24' height />
      <Voucher limitedOffer date={today} useCode={() => navigation.goBack()}>
        50% Discount
      </Voucher>
      <Spacer size='f24' height />
      <Voucher limitedOffer date={today} disabled>
        50% Discount
      </Voucher>
      <Spacer size='f24' height />
      <Voucher
        multiple
        count={2}
        date={today}
        useCode={() => navigation.goBack()}
      >
        50% Discount
      </Voucher>
    </Container>
  );
}
