import React, { useLayoutEffect } from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { Text, Colors, Gap, TabGroup, DividerLine } from 'advance-components';
import Container from '../components/container';
import useMobile from 'advance-components/src/hooks/use-mobile';

export default function PatternTabScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Pattern - Tab',
    });
  }, []);

  const mobile = useMobile();
  const getWidth = Platform.select({
    web: () => (mobile ? '100%' : 600),
    default: () => 'max-content',
  });

  const StyledColumn = styled.View`
    flex-direction: column;
    gap: 24;
    ${(props) =>
      props.custom && {
        backgroundColor: Colors.neutral100,
        paddingVertical: 16,
        paddingHorizontal: 4,
        width: getWidth(),
        alignSelf: 'start',
      }};
  `;

  return (
    <Container>
      <Text.Label>
        Note: individual tabs are displayed in{' '}
        <Text.Label
          color={Colors.primary40}
          onPress={() => navigation.navigate('ButtonScreen')}
          textDecorationLine='underline'
        >
          Button {'>'} Tab Buttons
        </Text.Label>
      </Text.Label>
      <Gap size='f24' height />
      <DividerLine />
      <Gap size='f24' height />
      <Text.BodyBase>Pattern - Tab A</Text.BodyBase>
      <StyledColumn custom>
        <TabGroup.A
          defaultActiveIndex={0}
          tabs={[
            { label: 'Page 1', onPress: () => {} },
            { label: 'Page 2', badgeCount: 999, onPress: () => {} },
          ]}
        />
        <TabGroup.A
          defaultActiveIndex={1}
          tabs={[
            { label: 'Page 1', onPress: () => {} },
            { label: 'Page 2', badgeCount: 99, onPress: () => {} },
            { label: 'Page 3', badgeCount: 999, onPress: () => {} },
          ]}
        />
        <TabGroup.A
          defaultActiveIndex={2}
          tabs={[
            { label: 'All', badgeCount: 999, onPress: () => {} },
            { label: 'Overdue', badgeCount: 4, onPress: () => {} },
            { label: 'Today', badgeCount: 5, onPress: () => {} },
            { label: 'Soon', badgeCount: 6, onPress: () => {} },
          ]}
        />
      </StyledColumn>
    </Container>
  );
}
