import React, { useLayoutEffect } from 'react';
import { ContentCard, Spacer, Text, illustrations } from 'advance-components';
import Container from '../components/container';

export default function ContentCardScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Content Card',
    });
  }, []);

  const contentCardProps = {
    imgSrc: illustrations.Warning,
    header: 'Title of Content If Applicable',
    body: 'Body content Lorem ipsum dolor sit amet, co nse ctetur adip iscing elit. Nullam dictum mau ris in tellus aliquet, vel venenatis turpis',
    link: { label: 'Interactive Call To Action' },
    maxWidth: 500,
    width: '100%',
  };

  const shortBody = 'Body content Lorem ipsum dolor sit amet,';

  return (
    <Container>
      <Text>Standard Variant</Text>
      <ContentCard {...contentCardProps} />
      <Spacer size='f16' height />

      <Text>Body CTA Variant</Text>
      <ContentCard {...contentCardProps} header='' />
      <Spacer size='f16' height />

      <Text>Title Body Variant</Text>
      <ContentCard {...contentCardProps} link={{}} />
      <Spacer size='f16' height />

      <Text>Body Only Variant</Text>
      <ContentCard {...contentCardProps} header='' link={{}} />
      <Spacer size='f16' height />

      <Text>Center Aligned Variant</Text>
      <ContentCard {...contentCardProps} body={shortBody} center />
      <Spacer size='f16' height />
    </Container>
  );
}
