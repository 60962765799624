import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components/native';
import { Icon, Text, Colors, Input } from 'advance-components';
import Container from '../components/container';
import Row from '../components/row';

const ADVANCE_LINE_ICONS = [
  'Airplane',
  'Bag',
  'Bank',
  'Cable',
  'Credit-Card',
  'Donate',
  'Education',
  'Electricity',
  'Employee',
  'Family',
  'Government',
  'Heart',
  'House',
  'ID',
  'Insurance',
  'Loans',
  'Medicine',
  'Memorial',
  'Others',
  'Personal',
  'Real-Estate',
  'Remittance',
  'Rent',
  'Schools',
  'Telecom',
  'Toll',
  'Virus',
  'Water',
];

const ADVANCE_FEATURE_ICONS = ['Request', 'Bills', 'Insurance'];

const ADVANCE_NAV_ICONS = [
  'home-solid',
  'home-line',
  'request-solid',
  'request-line',
  'transactions-solid',
  'transactions-line',
  'user-solid',
  'user-line',
];

const ADVANCE_ICONS = [
  {
    name: 'Files and Docs',
    icons: [
      'id-solid',
      'id-stroke',
      'document-solid',
      'document-stroke',
      'voucher-solid',
      'voucher-stroke',
      'image-solid',
      'image-stroke',
    ],
  },
  {
    name: 'Finance',
    icons: [
      'creditcard-solid',
      'creditcard-stroke',
      'coins-solid',
      'coins-stroke',
      'bank-solid',
      'bank-stroke',
      'money-solid',
      'money-stroke',
      'receipts-solid',
      'receipts-stroke',
      'insurance-solid',
      'insurance-stroke',
    ],
  },
  {
    name: 'Elements',
    icons: [
      'electricity-solid',
      'electricity-stroke',
      'water-solid',
      'water-stroke',
      'light-solid',
      'light-stroke',
    ],
  },
  {
    name: 'Transportation',
    icons: ['airplane-solid', 'airplane-stroke', 'car-solid', 'car-stroke'],
  },
  {
    name: 'Technology',
    icons: [
      'television-solid',
      'television-stroke',
      'mobile-solid',
      'mobile-stroke',
      'lightbulb-solid',
      'lightbulb-stroke',
      'camera-solid',
      'camera-stroke',
    ],
  },
  {
    name: 'Others',
    icons: [
      'family-solid',
      'family-stroke',
      'realestate-solid',
      'realestate-stroke',
      'education-solid',
      'education-stroke',
      'pill-solid',
      'pill-stroke',
      'gavel-solid',
      'gavel-stroke',
      'briefcase-solid',
      'briefcase-stroke',
      'calendar-solid',
      'calendar-stroke',
    ],
  },
  {
    name: 'Files',
    icons: [
      'download-solid',
      'download-stroke',
      'uploadfile-solid',
      'uploadfile-stroke',
      'copy-solid',
      'copy-stroke',
      'frame-solid',
      'frame-stroke',
    ],
  },
  {
    name: 'Transactions',
    icons: [
      'collection-solid',
      'collection-stroke',
      'loan-solid',
      'loan-stroke',
      'shopping-solid',
      'shopping-stroke',
      'remit-solid',
      'remit-stroke',
    ],
  },
  {
    name: 'Misc',
    icons: [
      'search-solid',
      'search-stroke',
      'hide-solid',
      'hide-stroke',
      'show-solid',
      'show-stroke',
      'flashoff-solid',
      'flashoff-stroke',
      'flashon-solid',
      'flashon-stroke',
    ],
  },
  {
    name: 'State',
    icons: [
      'help-solid',
      'help-stroke',
      'back-solid',
      'back-stroke',
      'forward-solid',
      'forward-stroke',
      'more-solid',
      'more-stroke',
      'favorite-solid',
      'favorite-stroke',
      'others-solid',
      'others-stroke',
      'arrowdown-solid',
      'arrowdown-stroke',
      'arrowup-solid',
      'arrowup-stroke',
      'arrowright-solid',
      'arrowright-stroke',
      'arrowleft-solid',
      'arrowleft-stroke',
      'account-solid',
      'account-stroke',
      'home-solid',
      'home-stroke',
      'notification-solid',
      'notification-stroke',
    ],
  },
  {
    name: 'Product',
    icons: [
      'warning-solid',
      'warning-stroke',
      'error-solid',
      'error-stroke',
      'information-solid',
      'information-stroke',
      'correct-solid',
      'correct-stroke',
      'wrong-solid',
      'wrong-stroke',
      'delete-solid',
      'delete-stroke',
      'close-solid',
      'close-stroke',
      'sort-solid',
      'sort-stroke',
    ],
  },
];

const IconItem = styled.View`
  width: 20%;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const LineIconContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const GroupIconItem = styled.View`
  align-items: center;
  justify-content: center;
  margin: 16px;
  min-width: fit-content;
  flex: 1;
  gap: 16px;
`;

const GroupIconContainer = styled.View`
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export default function IconScreen({ navigation }) {
  const [color, setColor] = useState(Colors.advanceBlue600);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Icon',
    });
  }, []);

  const colorOptions = Object.keys(Colors).map((color) => ({
    label: color,
    value: Colors[color],
  }));

  return (
    <Container>
      <Text.TitleBase>Advance Icons - Design System Revamp</Text.TitleBase>
      <Input.DropDown
        label='Icon color'
        defaultValue={color}
        onChange={(value) => {
          setColor(value.value);
        }}
        items={colorOptions}
      />
      {ADVANCE_ICONS.map((group, index) => (
        <GroupIconContainer key={index}>
          <Text.BodyBase>{group.name}</Text.BodyBase>
          <LineIconContainer key={index}>
            {group.icons.map((name, index) => (
              <GroupIconItem key={index}>
                <Icon.AdvanceIcons name={name} size={35} color={color} />
                <Text.LabelBase>{name}</Text.LabelBase>
              </GroupIconItem>
            ))}
          </LineIconContainer>
        </GroupIconContainer>
      ))}

      <Text.TitleBase>Advance Icons - Old Design System</Text.TitleBase>
      <Row>
        <Icon.MaterialIcons name='add-alert' size={35} />
        <Icon.MaterialIcons name='auto-delete' size={35} />
        <Icon.MaterialIcons name='error' size={35} />
        <Icon.MaterialIcons name='error-outline' size={35} />
        <Icon.MaterialIcons name='notification-important' size={35} />
        <Icon.MaterialIcons name='warning' size={35} />
      </Row>
      <LineIconContainer>
        {ADVANCE_LINE_ICONS.map((name, index) => (
          <IconItem key={index}>
            <Icon.AdvanceLineIcon name={name} size={35} />
          </IconItem>
        ))}
      </LineIconContainer>
      <LineIconContainer>
        {ADVANCE_FEATURE_ICONS.map((name, index) => (
          <IconItem key={index}>
            <Icon.AdvanceFeatureIcon name={name} size={35} />
          </IconItem>
        ))}
      </LineIconContainer>
      <LineIconContainer>
        {ADVANCE_NAV_ICONS.map((name, index) => (
          <IconItem key={index}>
            <Icon.AdvanceNavIcon name={name} size={35} />
          </IconItem>
        ))}
      </LineIconContainer>
    </Container>
  );
}
