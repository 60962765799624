import React, { useLayoutEffect } from 'react';
import { Spacer, Payment } from 'advance-components';
import Container from '../components/container';

export default function PaymentScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Payment',
    });
  }, []);

  return (
    <Container>
      <Payment
        icon='Bag'
        purpose='Travel'
        paymentNo={2}
        paymentCount={3}
        amount={1037}
      />
      <Spacer size='f16' height />
      <Payment
        icon='Bag'
        purpose='Travel'
        paymentNo={2}
        paymentCount={3}
        amount={1037}
        dueDate={new Date('2022-01-01')}
        carryOver
      />
      <Spacer size='f16' height />
      <Payment
        icon='Bag'
        purpose='Travel'
        paymentNo={1}
        paymentCount={3}
        amount={1037}
        dueDate={new Date('2022-01-15')}
        carryOver
      />
      <Spacer size='f16' height />
      <Payment.Grouped
        amount={2000}
        dueDate={new Date('2022-01-15')}
        payments={[
          {
            icon: 'Bag',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
          },
          {
            icon: 'Medicine',
            purpose: 'Medicine',
            paymentNo: 2,
            paymentCount: 4,
            amount: 1000,
          },
        ]}
      />
      <Spacer size='f16' height />
      <Payment.Grouped
        amount={2000}
        dueDate={new Date('2022-01-15')}
        carryOverCount={1}
        payments={[
          {
            icon: 'Bag',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
            dueDate: new Date('2022-12-30'),
            carryOver: true,
          },
          {
            icon: 'Medicine',
            purpose: 'Medicine',
            paymentNo: 2,
            paymentCount: 4,
            amount: 1000,
          },
        ]}
      />
      <Spacer size='f16' height />
      <Payment.Grouped
        amount={2000}
        dueDate={new Date('2022-01-15')}
        confirming
        payments={[
          {
            icon: 'Bag',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
          },
          {
            icon: 'Medicine',
            purpose: 'Medicine',
            paymentNo: 2,
            paymentCount: 4,
            amount: 1000,
          },
        ]}
      />
      <Spacer size='f16' height />
      <Payment.Grouped
        amount={2000}
        dueDate={new Date('2022-01-15')}
        paid
        payments={[
          {
            icon: 'Bag',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
          },
          {
            icon: 'Medicine',
            purpose: 'Medicine',
            paymentNo: 2,
            paymentCount: 4,
            amount: 1000,
          },
        ]}
      />
    </Container>
  );
}
