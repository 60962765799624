import React, { useLayoutEffect } from 'react';
import { Spacer, DocumentRow } from 'advance-components';
import Container from '../components/container';

export default function DocumentRowScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Document Row',
    });
  }, []);

  return (
    <Container>
      <DocumentRow name='Document Row'>Date/text here</DocumentRow>
      <Spacer size='f24' height />
      <DocumentRow name='Document Row 2'>December 15, 2021</DocumentRow>
      <Spacer size='f24' height />
      <DocumentRow name='Document Row with status' status='status here'>
        Date/text here
      </DocumentRow>
      <Spacer size='f24' height />
      <DocumentRow name='Document Row 2 with status' status='Verifying...'>
        December 15, 2021
      </DocumentRow>
    </Container>
  );
}
