import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import { Platform } from 'react-native';
import {
  Button,
  DividerLine,
  Spacer,
  Text,
  Icon,
  Colors,
} from 'advance-components';
import useMobile from 'advance-components/src/hooks/use-mobile';
import Container from '../components/container';

const FlexRow = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 16;
`;

export default function ButtonScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Button',
    });
  }, []);

  const [counter, setCounter] = React.useState(0);
  const mobile = useMobile();
  const getWidth = Platform.select({
    web: () => (mobile ? '100%' : 400),
    default: () => '100%',
  });
  const getPaddingHorizontal = Platform.select({
    web: () => (mobile ? 96 : 112),
    default: () => 96,
  });

  const StyledColumn = styled.View`
    flex-direction: column;
    gap: 16px;
    ${(props) =>
      props.custom && {
        backgroundColor: props.backgroundColor ?? Colors.neutral100,
        paddingTop: props.paddingVertical ?? props.padding ?? 32,
        paddingBottom: props.paddingVertical ?? props.padding ?? 32,
        paddingLeft: props.paddingHorizontal ?? props.padding ?? 32,
        paddingRight: props.paddingHorizontal ?? props.padding ?? 32,
        width: getWidth(),
        alignSelf: 'center',
        alignItems: 'center',
      }};
  `;

  const CustomFlexRow = styled(FlexRow)`
    background-color: ${Colors.neutral90};
    padding: 24px;
    width: ${getWidth()};
    margin-left: auto;
    margin-right: auto;
  `;

  return (
    <Container>
      <StyledColumn>
        <Text.BodyBase>Primary / Solid Button</Text.BodyBase>
        <Button>Primary</Button>
        <Button icon='info'>Primary with Leading Icon</Button>
        <Button icon='view-agenda' trailingIcon>
          Primary with Trailing Icon
        </Button>
        <FlexRow>
          <Button
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Primary - regular
          </Button>
          <Button
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
            size='small'
          >
            Primary - small
          </Button>
          <Button
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
            size='xs'
          >
            Primary - xs
          </Button>
        </FlexRow>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <StyledColumn>
        <Text.BodyBase>Primary / Hollow Button</Text.BodyBase>
        <Button type='hollow'>Hollow</Button>
        <Button type='hollow' icon='info'>
          Hollow with Leading Icon
        </Button>
        <Button type='hollow' icon='view-agenda' trailingIcon>
          Hollow with Trailing Icon
        </Button>
        <FlexRow>
          <Button
            type='hollow'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Hollow - regular
          </Button>
          <Button
            type='hollow'
            size='small'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Hollow - small
          </Button>
          <Button
            type='hollow'
            size='xs'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Hollow - xs
          </Button>
        </FlexRow>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <StyledColumn>
        <Text.BodyBase>Secondary Button</Text.BodyBase>
        <Button type='secondary'>Secondary</Button>
        <Button type='secondary' icon='info'>
          Secondary with Leading Icon
        </Button>
        <Button type='secondary' icon='view-agenda' trailingIcon>
          Secondary with Trailing Icon
        </Button>
        <FlexRow>
          <Button
            type='secondary'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Secondary - regular
          </Button>
          <Button
            type='secondary'
            size='small'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Secondary - small
          </Button>
          <Button
            type='secondary'
            size='xs'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Secondary - xs
          </Button>
        </FlexRow>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <StyledColumn>
        <Text.BodyBase>Text Only Button</Text.BodyBase>
        <Button type='text'>Text Only</Button>
        <Button type='text' icon='info'>
          Text Only with Leading Icon
        </Button>
        <Button type='text' icon='view-agenda' trailingIcon>
          Text Only with Trailing Icon
        </Button>
        <FlexRow>
          <Button
            type='text'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Text Only - regular
          </Button>
          <Button
            type='text'
            size='small'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Text Only - small
          </Button>
          <Button
            type='text'
            size='xs'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Text Only - xs
          </Button>
        </FlexRow>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <StyledColumn>
        <Text.BodyBase>Disabled Button</Text.BodyBase>
        <Button disabled>Disabled</Button>
        <Button disabled icon='info'>
          Disabled with Leading Icon
        </Button>
        <Button disabled icon='view-agenda' trailingIcon>
          Disabled with Trailing Icon
        </Button>
        <FlexRow>
          <Button
            disabled
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Disabled - regular
          </Button>
          <Button
            disabled
            size='small'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Disabled - small
          </Button>
          <Button
            disabled
            size='xs'
            icon='checkbox-blank'
            iconFamily={Icon.MaterialCommunityIcons}
          >
            Disabled - xs
          </Button>
        </FlexRow>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>
      <StyledColumn>
        <Text.BodyBase>Debounced Button Behavior</Text.BodyBase>
        <FlexRow>
          <Button onPress={() => setCounter(counter + 1)}>
            default with debounce (500ms)
          </Button>
          <Text.BodyBase>Number of clicks: {counter}</Text.BodyBase>
          <Button
            onPress={() => setCounter(counter + 1)}
            debounce={false}
            type='hollow'
          >
            debounce set to false
          </Button>
        </FlexRow>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <StyledColumn>
        <Text.BodyBase>Link Buttons</Text.BodyBase>
        <FlexRow>
          <Button.Link variant='filled' icon='help-stroke'>
            Filled Button
          </Button.Link>

          <Button.Link variant='filled' icon='help-stroke' disabled>
            Filled Button
          </Button.Link>
        </FlexRow>
        <FlexRow>
          <Button.Link
            variant='filled'
            icon='help-stroke'
            iconOrientation='right'
          >
            Filled Button
          </Button.Link>

          <Button.Link
            variant='filled'
            icon='help-stroke'
            iconOrientation='right'
            disabled
          >
            Filled Button
          </Button.Link>
        </FlexRow>
        <FlexRow>
          <Button.Link variant='tinted' icon='account-stroke'>
            Tinted Button
          </Button.Link>

          <Button.Link variant='tinted' icon='account-stroke' disabled>
            Tinted Button
          </Button.Link>
        </FlexRow>
        <FlexRow>
          <Button.Link
            variant='tinted'
            icon='account-stroke'
            iconOrientation='right'
          >
            Tinted Button
          </Button.Link>

          <Button.Link
            variant='tinted'
            icon='account-stroke'
            iconOrientation='right'
            disabled
          >
            Tinted Button
          </Button.Link>
        </FlexRow>
        <FlexRow>
          <Button.Link variant='ghost' icon='home-stroke'>
            Ghost Button
          </Button.Link>

          <Button.Link variant='ghost' icon='home-stroke' disabled>
            Ghost Button
          </Button.Link>
        </FlexRow>
        <FlexRow>
          <Button.Link
            variant='ghost'
            icon='home-stroke'
            iconOrientation='right'
          >
            Ghost Button
          </Button.Link>

          <Button.Link
            variant='ghost'
            icon='home-stroke'
            iconOrientation='right'
            disabled
          >
            Ghost Button
          </Button.Link>
        </FlexRow>
        <FlexRow>
          <Button.Link variant='outline' icon='notification-stroke'>
            Outline Button
          </Button.Link>

          <Button.Link variant='outline' icon='notification-stroke' disabled>
            Outline Button
          </Button.Link>
        </FlexRow>
        <FlexRow>
          <Button.Link
            variant='outline'
            icon='notification-stroke'
            iconOrientation='right'
          >
            Outline Button
          </Button.Link>

          <Button.Link
            variant='outline'
            icon='notification-stroke'
            iconOrientation='right'
            disabled
          >
            Outline Button
          </Button.Link>
        </FlexRow>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <StyledColumn>
        <Text.BodyBase>Square Buttons</Text.BodyBase>
        <FlexRow>
          <Button.Square icon='others-stroke'>Option 1</Button.Square>
        </FlexRow>
        <FlexRow>
          <Button.Square icon='others-stroke' isSelected>
            Option 1
          </Button.Square>
        </FlexRow>
        <FlexRow>
          <Button.Square icon='others-stroke' disabled>
            Option 1
          </Button.Square>
        </FlexRow>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <StyledColumn>
        <Text.BodyBase>Icon Buttons</Text.BodyBase>
        <StyledColumn custom>
          <FlexRow>
            <Button.Icon icon='calendar-solid' type='filled' />
            <Button.Icon icon='calendar-solid' type='tinted' />
            <Button.Icon icon='calendar-solid' />
          </FlexRow>
          <Spacer size='f16' height />
          <FlexRow>
            <Button.Icon
              icon='calendar-solid'
              colorway='neutral'
              type='filled'
            />
            <Button.Icon
              icon='calendar-solid'
              colorway='neutral'
              type='tinted'
            />
            <Button.Icon icon='calendar-solid' colorway='neutral' />
          </FlexRow>
          <Spacer size='f16' height />
          <FlexRow>
            <Button.Icon icon='calendar-solid' type='filled' disabled />
            <Button.Icon icon='calendar-solid' type='tinted' disabled />
            <Button.Icon icon='calendar-solid' disabled />
          </FlexRow>
        </StyledColumn>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <StyledColumn>
        <Text.BodyBase>Tab Buttons</Text.BodyBase>
        <CustomFlexRow>
          <Button.Tab>Default Tab</Button.Tab>
          <Button.Tab type='active'>Active Tab</Button.Tab>
        </CustomFlexRow>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <StyledColumn>
        <Text.BodyBase>Vertical Buttons</Text.BodyBase>
        <StyledColumn custom>
          <FlexRow>
            <Button.Vertical icon='home-stroke'>Product 1</Button.Vertical>
            <Button.Vertical icon='home-stroke' disabled>
              Product 2
            </Button.Vertical>
          </FlexRow>
        </StyledColumn>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <StyledColumn>
        <Text.BodyBase>Vertical Navigation Buttons</Text.BodyBase>
        <StyledColumn custom paddingHorizontal={getPaddingHorizontal()}>
          <Button.VerticalNavigation icon='home-stroke'>
            Home
          </Button.VerticalNavigation>
          <Button.VerticalNavigation icon='home-stroke' active>
            Home
          </Button.VerticalNavigation>
          <Button.VerticalNavigation icon='home-stroke' disabled>
            Home
          </Button.VerticalNavigation>
          <Spacer size='f16' height />
          <Button.VerticalNavigation
            orientation='horizontal'
            icon='notification-stroke'
          >
            Notifications
          </Button.VerticalNavigation>
          <Button.VerticalNavigation
            orientation='horizontal'
            icon='notification-stroke'
            active
          >
            Notifications
          </Button.VerticalNavigation>
          <Button.VerticalNavigation
            orientation='horizontal'
            icon='notification-stroke'
            disabled
          >
            Notifications
          </Button.VerticalNavigation>
        </StyledColumn>
        <StyledColumn
          custom
          paddingHorizontal={getPaddingHorizontal()}
          backgroundColor={Colors.primary40}
        >
          <Button.VerticalNavigation theme='dark' icon='home-stroke'>
            Home
          </Button.VerticalNavigation>
          <Button.VerticalNavigation theme='dark' icon='home-stroke' active>
            Home
          </Button.VerticalNavigation>
          <Button.VerticalNavigation theme='dark' icon='home-stroke' disabled>
            Home
          </Button.VerticalNavigation>
          <Spacer size='f16' height />
          <Button.VerticalNavigation
            theme='dark'
            orientation='horizontal'
            icon='notification-stroke'
          >
            Notifications
          </Button.VerticalNavigation>
          <Button.VerticalNavigation
            theme='dark'
            orientation='horizontal'
            icon='notification-stroke'
            active
          >
            Notifications
          </Button.VerticalNavigation>
          <Button.VerticalNavigation
            theme='dark'
            orientation='horizontal'
            icon='notification-stroke'
            disabled
          >
            Notifications
          </Button.VerticalNavigation>
        </StyledColumn>
        <Spacer size='f16' height />
        <DividerLine />
        <Spacer size='f16' height />
      </StyledColumn>

      <Text.BodyBase>V1 Buttons</Text.BodyBase>
      <DividerLine />
      <Spacer size='f16' height />
      <Text.BodyBase>Rectangular Buttons</Text.BodyBase>
      <Spacer size='f16' height />
      <Button.Rectangle type='initial' headerLeft='1 Payroll'>
        ₱ 0.00
      </Button.Rectangle>
      <Spacer size='f12' height />
      <Button.Rectangle headerLeft='1 Payroll'>₱ 7,650.00</Button.Rectangle>
      <Spacer size='f12' height />
      <Button.Rectangle type='selected' headerLeft='1 Payroll'>
        ₱ 7,650.00
      </Button.Rectangle>
    </Container>
  );
}
