import React, { useLayoutEffect } from 'react';
import { Spacer, DocumentLink, Colors } from 'advance-components';
import Container from '../components/container';

export default function DocumentLinkScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Document Link',
    });
  }, []);

  return (
    <Container>
      <DocumentLink name='Document Link' />
      <Spacer size='f24' height />
      <DocumentLink name='Document Link 2' />
      <Spacer size='f24' height />
      <DocumentLink name='Document Link with status' status='status here' />
      <Spacer size='f24' height />
      <DocumentLink name='Document Link 2 with status' status='Verifying...' />
      <Spacer size='f24' height />
      <DocumentLink
        name='Document Link with custom status color'
        status='Error'
        statusColor={Colors.error400}
      />
    </Container>
  );
}
