import React, { useLayoutEffect } from 'react';
import { Spacer, ListItem, Icon } from 'advance-components';
import Container from '../components/container';
import logo from '../../assets/aa-logo.png';

export default function ListItemScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'List Item',
    });
  }, []);

  const onPress = () => {
    // eslint-disable-next-line no-alert
    alert('List Item Pressed');
  };

  return (
    <Container>
      <ListItem
        headline='Headline'
        subtext='Subtext'
        leftIconName={'appstore-o'}
        leftIconFamily={Icon.AntDesign}
        rightIconName={'information-solid'}
        onPress={onPress}
      />
      <Spacer size='f24' height />
      <ListItem
        headline='Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        subtext='Subtext'
        leftIconName={'appstore-o'}
        leftIconFamily={Icon.AntDesign}
        rightIconName={'information-solid'}
        onPress={onPress}
      />
      <Spacer size='f24' height />
      <ListItem
        headline='Headline'
        subtext='Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        leftIconName={'appstore-o'}
        leftIconFamily={Icon.AntDesign}
        rightIconName={'information-solid'}
        onPress={onPress}
      />
      <Spacer size='f24' height />
      <ListItem headline='What is the employer code?' onPress={onPress} />
      <Spacer size='f24' height />
      <ListItem
        headline='Mobile Number'
        subtext='+63917888231'
        onPress={onPress}
      />
      <Spacer size='f24' height />
      <ListItem
        headline='Mobile Number'
        subtext='+63917888231'
        buttonLabel='Change'
        onPress={onPress}
      />
      <Spacer size='f24' height />
      <ListItem imgSrc={logo} headline='Headline' />
      <Spacer size='f24' height />
      <ListItem
        imgSrc={logo}
        headline='Headline'
        subtext='Requested on 28 JUL 2020'
      />
      <Spacer size='f24' height />
    </Container>
  );
}
