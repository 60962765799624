import React, { useLayoutEffect } from 'react';
import { View } from 'react-native';
import { Gap, Text, Colors, ProgressIndicator } from 'advance-components';
import Container from '../components/container';

export default function ProgressIndicatorScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'ProgressIndicator',
    });
  }, []);

  const styles = {
    container: {
      padding: 40,
    },
    groupContainer: {
      backgroundColor: Colors.neutral100,
      maxWidth: 600,
      borderRadius: Gap.sizes.f8,
    },
    verticalItemContainer: {
      height: 76,
    },
    verticalPairContainer: {
      height: 152,
    },
    verticalGroupContainer: {
      height: 228,
    },
  };

  return (
    <Container style={styles.container}>
      <Text.Title>Wizard</Text.Title>
      <Gap size='f24' height />

      <Container style={styles.groupContainer}>
        <Text.Body weight='semi'>Horizontal</Text.Body>
        <Gap size='f48' height />
        <Text.Label>Current</Text.Label>
        <Gap size='f24' height />

        <ProgressIndicator.Wizard
          steps={[{ label: 'Step title', isActive: true, isComplete: false }]}
        />

        <Gap size='f24' height />
        <Text.Label>Complete</Text.Label>
        <Gap size='f24' height />

        <ProgressIndicator.Wizard
          steps={[{ label: 'Step title', isActive: false, isComplete: true }]}
        />

        <Gap size='f24' height />
        <Text.Label>Incomplete</Text.Label>
        <Gap size='f24' height />

        <ProgressIndicator.Wizard
          steps={[{ label: 'Step title', isActive: false, isComplete: false }]}
        />

        <Gap size='f48' height />
        <Text.Label>Sample</Text.Label>
        <Gap size='f24' height />

        <ProgressIndicator.Wizard
          steps={[
            { label: 'Step title', isActive: false, isComplete: true },
            { label: 'Step title', isActive: true, isComplete: false },
          ]}
        />

        <Gap size='f48' height />

        <ProgressIndicator.Wizard
          steps={[
            { label: 'Loan info', isActive: false, isComplete: true },
            { label: 'Upload files', isActive: true, isComplete: false },
            { label: 'Contracts', isActive: false, isComplete: false },
          ]}
        />
      </Container>

      <Gap size='f24' height />

      <Container style={styles.groupContainer}>
        <Text.Body weight='semi'>Vertical</Text.Body>
        <Gap size='f48' height />
        <Text.Label>Current</Text.Label>
        <Gap size='f24' height />

        <View style={styles.verticalItemContainer}>
          <ProgressIndicator.Wizard
            orientation='vertical'
            steps={[{ label: 'Step title', isActive: true, isComplete: false }]}
          />
        </View>

        <Gap size='f24' height />
        <Text.Label>Complete</Text.Label>
        <Gap size='f24' height />

        <View style={styles.verticalItemContainer}>
          <ProgressIndicator.Wizard
            orientation='vertical'
            steps={[{ label: 'Step title', isActive: false, isComplete: true }]}
          />
        </View>

        <Gap size='f24' height />
        <Text.Label>Incomplete</Text.Label>
        <Gap size='f24' height />

        <View style={styles.verticalItemContainer}>
          <ProgressIndicator.Wizard
            orientation='vertical'
            steps={[
              { label: 'Step title', isActive: false, isComplete: false },
            ]}
          />
        </View>

        <Gap size='f48' height />
        <Text.Label>Sample</Text.Label>
        <Gap size='f24' height />

        <View style={styles.verticalPairContainer}>
          <ProgressIndicator.Wizard
            orientation='vertical'
            steps={[
              { label: 'Step title', isActive: false, isComplete: true },
              { label: 'Step title', isActive: true, isComplete: false },
            ]}
          />
        </View>

        <Gap size='f48' height />

        <View style={styles.verticalGroupContainer}>
          <ProgressIndicator.Wizard
            orientation='vertical'
            steps={[
              { label: 'Loan info', isActive: false, isComplete: true },
              { label: 'Upload files', isActive: true, isComplete: false },
              { label: 'Contracts', isActive: false, isComplete: false },
            ]}
          />
        </View>

        <Gap size='f24' height />
      </Container>
    </Container>
  );
}
