import React, { useLayoutEffect } from 'react';
import { Text, Spacer } from 'advance-components';
import styled from 'styled-components/native';
import Container from '../components/container';
import qr from '../../assets/qr.jpg';

const SCREENS = [
  'AdvanceBreakdownScreen',
  'AdvanceDetailsHeaderScreen',
  'AlertScreen',
  'BadgeScreen',
  'BreadcrumbsScreen',
  'BottomSheetScreen',
  'BreakdownRowScreen',
  'ButtonScreen',
  'CameraScreen',
  'CheckboxScreen',
  'ColorsScreen',
  'ContentCardScreen',
  'ContentGroupScreen',
  'CreditLineWidgetsScreen',
  'DatePickerScreen',
  'DividerLineScreen',
  'DocumentLinkScreen',
  'DocumentRowScreen',
  'HeadingGroupScreen',
  'GapScreen',
  'HighlightedContentScreen',
  'IconScreen',
  'IllustrationsScreen',
  'ImagesScreen',
  'InputScreen',
  'InstructionsScreen',
  'KycRequirementsScreen',
  'LabelDataPairScreen',
  'LayoutTokensScreen',
  'ListItemScreen',
  'LoginPinScreen',
  'LozengeScreen',
  'ModalScreen',
  'NavigationScreen',
  'NavigationListItemScreen',
  'NavigationMenuScreen',
  'NotificationRowScreen',
  'OverlayScreen',
  'PageControlScreen',
  'PageEndScreen',
  'PageHandlerScreen',
  'PaginationScreen',
  'PanelScreen',
  'PaymentScreen',
  'PillScreen',
  'PinKeypadScreen',
  'ProgressBarScreen',
  'ProgressHeaderScreen',
  'ProgressIndicatorScreen',
  'RadioScreen',
  'ReferralsScreen',
  'RequirementsScreen',
  'SectionTitleScreen',
  'SelectionScreen',
  'SnackbarScreen',
  'SpacerScreen',
  'SpecialCardScreen',
  'StatusScreen',
  'ShadowScreen',
  'SystemBarScreen',
  'TextGroupScreen',
  'TitleBarScreen',
  'ToggleScreen',
  'TooltipScreen',
  'TransactionScreen',
  'TypographyScreen',
  'VoucherScreen',
  'WizardHeadersAndFootersScreen',
];

const QrImage = styled.Image`
  height: 200px;
  width: 200px;
`;

const Item = styled.Pressable`
  border: 1px solid #000;
  padding: 10px;
`;

export default function HomeScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Advance Components',
    });
  }, []);

  return (
    <Container>
      <QrImage source={qr} />
      <Spacer size='f8' height />
      <Text.LabelBold>Scan QR using:</Text.LabelBold>
      <Text.LabelBase>
        Android:{' '}
        <Text.LabelBase href='https://play.google.com/store/apps/details?id=host.exp.exponent'>
          https://play.google.com/store/apps/details?id=host.exp.exponent
        </Text.LabelBase>
      </Text.LabelBase>
      <Text.LabelBase>
        iOS:{' '}
        <Text.LabelBase href='https://apps.apple.com/us/app/expo-go/id982107779'>
          https://apps.apple.com/us/app/expo-go/id982107779
        </Text.LabelBase>
      </Text.LabelBase>
      <Spacer size='f8' height />
      {SCREENS.map((screen) => (
        <Item key={screen} onPress={() => navigation.push(screen)}>
          <Text.TitleBase>{screen.replace('Screen', '')}</Text.TitleBase>
        </Item>
      ))}
      <Spacer size='f8' height />
    </Container>
  );
}
