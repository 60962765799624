import React, { useState } from 'react';
import { Camera } from 'advance-components';
import qr from '../../assets/qr.jpg';

export default function CameraFrameScreen({ navigation, route }) {
  const [image, setImage] = useState(null);

  return (
    <Camera
      navigation={navigation}
      title='Take a photo of your company ID'
      type={route.params.type}
      currentImage={image}
      takePhoto={() => setImage(qr)}
      retakePhoto={() => setImage(null)}
    />
  );
}
