import React, { useLayoutEffect, useState, useRef } from 'react';
import moment from 'moment';
import { View, Platform } from 'react-native';
import { DatePicker, Input, Text } from 'advance-components';
import Container from '../components/container';

export default function DatePickerScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Date Picker',
    });
  }, []);

  const onClick = (ref) => {
    if (Platform.OS === 'web') {
      ref.current.click();
    } else {
      return null;
    }
  };

  const [pastDate, setPastDate] = useState(moment().toDate());
  const [futureDate, setFutureDate] = useState(moment().toDate());
  const futureDateRef = useRef(null);
  const pastDateRef = useRef(null);

  return (
    <Container>
      <DatePicker
        minimumDate={moment().toDate()}
        maximumDate={moment().add(5, 'years').toDate()}
        value={moment(futureDate)}
        onChange={(selectedDate) => setFutureDate(selectedDate)}
        defaultSelectedDate={moment(futureDate)}
        reverseYears
      >
        <View ref={futureDateRef}>
          <Input.TextField
            righticon='calendar-today'
            label='Future dates (5 years)'
            placeholder='Select'
            value={futureDate && moment(futureDate).format('MMMM DD, YYYY')}
            righticonPress={() => onClick(futureDateRef)}
          />
        </View>
        <Text>Press to open date picker for mobile</Text>
      </DatePicker>
      <DatePicker
        maximumDate={moment().toDate()}
        minimumDate={moment().subtract(100, 'years').toDate()}
        value={moment(pastDate)}
        onChange={(selectedDate) => setPastDate(selectedDate)}
        defaultSelectedDate={moment(pastDate)}
      >
        <View ref={pastDateRef}>
          <Input.TextField
            righticon='calendar-today'
            label='Past dates (100 years)'
            placeholder='Select'
            value={pastDate && moment(pastDate).format('MMMM DD, YYYY')}
            righticonPress={() => onClick(pastDateRef)}
          />
          <Text>Press to open date picker for mobile</Text>
        </View>
      </DatePicker>
    </Container>
  );
}
