import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import { Selection, Gap, Text, DividerLine } from 'advance-components';
import Container from '../components/container';

const StyledContainer = styled.View`
  align-items: flex-start;
  justify-content: center;
  padding: 40px;
`;

export default function SelectionScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Selection',
    });
  }, []);

  return (
    <Container>
      <StyledContainer>
        <Text.TitleLarge weight='bold'>Pill</Text.TitleLarge>
        <Gap size='f40' height />
        <Selection.Pill label='Option 1' />
        <Gap size='f40' height />
        <Selection.Pill label='Option 2' isActive />
        <Gap size='f40' height />
        <Selection.Pill label='Option 3' disabled />

        <Gap size='f56' height />
        <DividerLine />
        <Gap size='f56' height />

        <Text.TitleLarge weight='bold'>Card</Text.TitleLarge>
        <Gap size='f40' height />
        <Selection.Card label='Option 1' subLabel='Supporting text' />
        <Gap size='f40' height />
        <Selection.Card label='Option 2' subLabel='Supporting text' isActive />
        <Gap size='f40' height />
        <Selection.Card label='Option 3' subLabel='Supporting text' disabled />
      </StyledContainer>
    </Container>
  );
}
