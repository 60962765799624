import React, { useLayoutEffect } from 'react';
import { Spacer, ProgressBar } from 'advance-components';
import Container from '../components/container';

export default function ProgressBarScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Progress Bar',
    });
  }, []);

  return (
    <Container>
      <ProgressBar progress={0} />
      <Spacer size='f24' height />
      <ProgressBar progress={25} />
      <Spacer size='f24' height />
      <ProgressBar progress={50} />
      <Spacer size='f24' height />
      <ProgressBar progress={75} />
      <Spacer size='f24' height />
      <ProgressBar progress={100} />
    </Container>
  );
}
