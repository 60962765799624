import React, { useLayoutEffect } from 'react';
import { Colors, SystemBar } from 'advance-components';

export default function SystemBarDarkScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'System Bar Dark',
      headerTintColor: Colors.white,
      headerStyle: {
        backgroundColor: Colors.indigo400,
      },
    });
  }, []);

  return <SystemBar theme='dark' />;
}
