import React, { useLayoutEffect } from 'react';
import { Spacer, Lozenge } from 'advance-components';
import Container from '../components/container';

export default function LozengeScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Lozenge',
    });
  }, []);

  return (
    <Container>
      {/* Positive */}
      <Lozenge type='positive' filled={false}>
        Positive
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='positive' filled={false} icon='information-solid'>
        Positive
      </Lozenge>
      <Spacer size='f24' height />
      <Lozenge type='positive' filled={true}>
        Positive
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='positive' filled={true} icon='information-solid'>
        Positive
      </Lozenge>
      <Spacer size='f24' height />

      {/* Caution */}
      <Lozenge type='caution' filled={false}>
        Caution
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='caution' filled={false} icon='information-solid'>
        Caution
      </Lozenge>
      <Spacer size='f24' height />
      <Lozenge type='caution' filled={true}>
        Caution
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='caution' filled={true} icon='information-solid'>
        Caution
      </Lozenge>
      <Spacer size='f24' height />

      {/* Negative */}
      <Lozenge type='negative' filled={false}>
        Negative
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='negative' filled={false} icon='information-solid'>
        Negative
      </Lozenge>
      <Spacer size='f24' height />
      <Lozenge type='negative' filled={true}>
        Negative
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='negative' filled={true} icon='information-solid'>
        Negative
      </Lozenge>
      <Spacer size='f24' height />

      {/* Neutral */}
      <Lozenge type='neutral' filled={false}>
        Neutral
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='neutral' filled={false} icon='information-solid'>
        Neutral
      </Lozenge>
      <Spacer size='f24' height />
      <Lozenge type='neutral' filled={true}>
        Neutral
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='neutral' filled={true} icon='information-solid'>
        Neutral
      </Lozenge>
      <Spacer size='f24' height />

      {/* Informative */}
      <Lozenge type='informative' filled={false}>
        Informative
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='informative' filled={false} icon='information-solid'>
        Informative
      </Lozenge>
      <Spacer size='f24' height />
      <Lozenge type='informative' filled={true}>
        Informative
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='informative' filled={true} icon='information-solid'>
        Informative
      </Lozenge>
      <Spacer size='f24' height />
    </Container>
  );
}
