import React, { useLayoutEffect } from 'react';
import { Panel, Spacer, Colors } from 'advance-components';
import { View, ScrollView } from 'react-native';

const styles = {
  container: {
    padding: 10,
    height: '100%',
  },
  overlay: {
    width: '100%',
    height: '100%',
    background: Colors.gray600,
    opacity: 0.4,
  },
  modal: {
    margin: 'auto',
    padding: 100,
    width: '50%',
  },
};

export default function PanelScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Panel',
    });
  }, []);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Panel style={styles.container}>
        <View />
      </Panel>
      <Spacer size='d40' height />
      <View style={styles.overlay}>
        <Panel type='modal' style={styles.modal}>
          <View />
        </Panel>
      </View>
    </ScrollView>
  );
}
