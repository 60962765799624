import React, { useLayoutEffect } from 'react';
import { Spacer, LabelDataPair } from 'advance-components';
import Container from '../components/container';

export default function LabelDataPairScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Label Data Pair',
    });
  }, []);

  return (
    <Container>
      <LabelDataPair type='top-left' size='small' label='Label'>
        Top Left Small
      </LabelDataPair>
      <Spacer size='f24' height />
      <LabelDataPair type='top-right' size='small' label='Label'>
        Top Right Small
      </LabelDataPair>
      <Spacer size='f24' height />
      <LabelDataPair type='bottom-right' size='small' label='Label'>
        Bottom Right Small
      </LabelDataPair>
      <Spacer size='f24' height />
      <LabelDataPair type='bottom-left' size='small' label='Label'>
        Bottm Left Small
      </LabelDataPair>
      <Spacer size='f24' height />
      <LabelDataPair type='top-left' size='regular' label='Label'>
        Top Left Regular
      </LabelDataPair>
      <Spacer size='f24' height />
      <LabelDataPair type='top-right' size='regular' label='Label'>
        Top Right Regular
      </LabelDataPair>
      <Spacer size='f24' height />
      <LabelDataPair type='bottom-right' size='regular' label='Label'>
        Bottom Right Regular
      </LabelDataPair>
      <Spacer size='f24' height />
      <LabelDataPair type='bottom-left' size='regular' label='Label'>
        Bottm Left Regular
      </LabelDataPair>
    </Container>
  );
}
