import React, { useLayoutEffect } from 'react';
import { Platform, View, ScrollView } from 'react-native';
import { Input, Gap, Text, DividerLine } from 'advance-components';
import styled from 'styled-components/native';

const ResponsiveContainer = ({ children, style }) => {
  const Container = Platform.OS === 'web' ? View : ScrollView;

  return <Container style={style}>{children}</Container>;
};

const StyledCustomComponent = styled(ResponsiveContainer)`
  padding: 12px;
`;

export default function FileUploadScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'File Upload Input',
    });
  }, []);

  const onSelectFileSource = (source) => {
    // eslint-disable-next-line no-alert
    alert(source);
  };

  const onOpenFileSelector = () => {
    // eslint-disable-next-line no-alert
    alert('OPEN FILE SELECTOR');
  };

  const onPressHelp = () => {
    // eslint-disable-next-line no-alert
    alert('HELP');
  };

  const onPressUpload = () => {
    // eslint-disable-next-line no-alert
    alert('UPLOAD');
  };

  const onPressReupload = () => {
    // eslint-disable-next-line no-alert
    alert('REUPLOAD');
  };

  const onPressDelete = () => {
    // eslint-disable-next-line no-alert
    alert('DELETE');
  };

  const onPressPreview = () => {
    // eslint-disable-next-line no-alert
    alert('PREVIEW');
  };

  const onPressDownload = () => {
    // eslint-disable-next-line no-alert
    alert('DOWNLOAD');
  };

  const onPressCancel = () => {
    // eslint-disable-next-line no-alert
    alert('CANCEL');
  };

  const onPressEdit = () => {
    // eslint-disable-next-line no-alert
    alert('EDIT');
  };

  const maxFileCountUploadedFiles = [
    {
      status: 'error',
      fileData: {
        error: 'errorMessage',
      },
      fileName: 'file1.jpg',
      number: 1,
      defaultSortingNumber: 1,
    },
    {
      status: 'error',
      fileData: {
        error: 'errorMessage',
      },
      fileName: 'file2.jpg',
      number: 2,
      defaultSortingNumber: 2,
    },
    {
      status: 'uploaded',
      fileData: {
        error: '',
      },
      fileName: 'file3.jpg',
      number: 3,
      defaultSortingNumber: 3,
      description: 'JPG file',
    },
    {
      status: 'uploaded',
      fileData: {
        error: '',
      },
      fileName: 'file4.csv',
      number: 4,
      defaultSortingNumber: 4,
      description: 'CSV file',
    },
    {
      status: 'uploading',
      fileData: {
        error: '',
      },
      fileName: 'file5.csv',
      number: 5,
      defaultSortingNumber: 5,
      progress: 50,
    },
  ];

  const multipleDefaultContext = {
    removeUploadedFile: (index, defaultSortingNumber) => {
      // eslint-disable-next-line no-alert
      alert(
        `DELETE file index: ${index}, sorting number: ${defaultSortingNumber}`,
      );
    },
    cancelUploading: (index, number) => {
      // eslint-disable-next-line no-alert
      alert(`CANCEL upload file index: ${index}, number: ${number}`);
    },
    previewFile: (defaultSortingNumber) => {
      // eslint-disable-next-line no-alert
      alert(`PREVIEW sorting number: ${defaultSortingNumber}`);
    },
    downloadFile: (defaultSortingNumber) => {
      // eslint-disable-next-line no-alert
      alert(`DOWNLOAD sorting number: ${defaultSortingNumber}`);
    },
    onReuploadClick: (defaultSortingNumber) => {
      // eslint-disable-next-line no-alert
      alert(`REUPLOAD sorting number: ${defaultSortingNumber}`);
    },
  };

  const multipleCompleteContext = {
    uploadedFiles: [
      {
        status: 'uploaded',
        fileData: {
          error: '',
        },
        fileName: 'file1.jpg',
        description: 'JPG file',
        number: 1,
        defaultSortingNumber: 1,
      },
      {
        status: 'uploaded',
        fileData: {
          error: '',
        },
        fileName: 'file2.csv',
        description: 'CSV file',
        number: 2,
        defaultSortingNumber: 2,
      },
    ],
    dragAndDropCard: {
      onPress: onOpenFileSelector,
      helperText: 'Helper text',
      errorMessage: 'Error message',
    },
    ...multipleDefaultContext,
  };

  const multipleCompleteProps = {
    helperText: 'Helper text',
    multiple: true,
    context: multipleCompleteContext,
    helpFunction: onPressHelp,
    onSelectFileSource,
  };

  return (
    <StyledCustomComponent>
      <Text.Body weight='bold'>Single - Empty Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        onPress={onPressUpload}
        helperText='Helper text'
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Single - Uploading Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        fileName='file.jpg'
        progress={50}
        helperText='Helper text'
        onCancel={onPressCancel}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Single - Complete Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        fileName='file.jpg'
        helperText='Helper text'
        onPreview={onPressPreview}
        onDelete={onPressDelete}
      />
      <Gap size='f12' height />
      <Input.DocumentUpload
        fileName='file.jpg'
        helperText='Helper text'
        showDownload
        onDownload={onPressDownload}
        onDelete={onPressDelete}
      />
      <Gap size='f12' height />
      <Input.DocumentUpload
        fileName='file.jpg'
        helperText='Helper text'
        showDownload
        onDownload={onPressDownload}
        onDelete={onPressDelete}
        badge={{
          type: 'dot',
          variant: 'alert',
        }}
      />
      <Gap size='f12' height />
      <Input.DocumentUpload
        fileName='file.jpg'
        helperText='Helper text'
        showDownload
        onDownload={onPressDownload}
        onDelete={onPressDelete}
        badge={{
          type: 'icon',
          variant: 'warning',
        }}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Single - Failed Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        fileName='file.jpg'
        helperText='Error message'
        onReupload={onPressReupload}
        onDelete={onPressDelete}
        error
      />

      <Gap size='f12' height />
      <Input.DocumentUpload
        error
        onPress={onPressUpload}
        helperText='Upload required'
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>
        Single - Label Data Pair Display Version
      </Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        variant='display'
        fileName='file.jpg'
        helperText='Helper text'
        onPreview={onPressPreview}
        onDownload={onPressDownload}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Single - Label Data Pair Edit Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        variant='edit'
        fileName='file.jpg'
        helperText='Helper text'
        onEdit={onPressEdit}
        onDelete={onPressDelete}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Single - Disabled Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload helperText='Helper text' disabled />

      <Gap size='f48' height />
      <DividerLine />
      <Gap size='f48' height />

      <Text.Body weight='bold'>Multiple - Empty Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        helperText='Helper text'
        multiple
        context={{
          uploadedFiles: [],
          dragAndDropCard: {
            onPress: onOpenFileSelector,
          },
          ...multipleDefaultContext,
        }}
        helpFunction={onPressHelp}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Multiple - Uploading Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        helperText='Helper text'
        multiple
        context={{
          uploadedFiles: [
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file1.docx',
              number: 1,
              defaultSortingNumber: 1,
            },
            {
              status: 'uploading',
              fileData: {
                error: '',
              },
              fileName: 'file2.jpg',
              progress: 50,
              number: 2,
              defaultSortingNumber: 2,
            },
          ],
          dragAndDropCard: {
            onPress: onOpenFileSelector,
          },
          ...multipleDefaultContext,
        }}
        helpFunction={onPressHelp}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Multiple - Complete Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload {...multipleCompleteProps} />
      <Gap size='f12' height />
      <Input.DocumentUpload
        badge={{
          type: 'dot',
          variant: 'alert',
        }}
        {...multipleCompleteProps}
      />
      <Gap size='f12' height />
      <Input.DocumentUpload
        badge={{
          type: 'icon',
          variant: 'warning',
        }}
        {...multipleCompleteProps}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Multiple - Max Count Reached Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        helperText='Helper text'
        multiple
        maxFileUploadCount={2}
        context={{
          uploadedFiles: [
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file1.jpg',
              description: 'JPG file',
              number: 1,
              defaultSortingNumber: 1,
            },
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file2.csv',
              description: 'CSV file',
              number: 2,
              defaultSortingNumber: 2,
            },
          ],
          dragAndDropCard: {
            onPress: onOpenFileSelector,
            helperText: 'Helper text',
            errorMessage: 'Error message',
          },
          ...multipleDefaultContext,
        }}
        helpFunction={onPressHelp}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>
        Multiple - Drag and Drop Disabled Version
      </Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        helperText='Helper text'
        multiple
        maxFileUploadCount={2}
        context={{
          uploadedFiles: [
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file1.jpg',
              description: 'JPG file',
              number: 1,
              defaultSortingNumber: 1,
            },
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file2.csv',
              description: 'CSV file',
              number: 2,
              defaultSortingNumber: 2,
            },
          ],
          dragAndDropCard: {
            onPress: onOpenFileSelector,
            helperText: 'Helper text',
            errorMessage: 'Error message',
            disabled: true,
          },
          ...multipleDefaultContext,
        }}
        helpFunction={onPressHelp}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>
        Multiple - Error Version (Below Max Count)
      </Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        helperText='Error message'
        multiple
        context={{
          uploadedFiles: [
            {
              status: 'error',
              fileData: {
                error: 'errorMessage',
              },
              fileName: 'file1.jpg',
              number: 1,
              defaultSortingNumber: 1,
            },
            {
              status: 'error',
              fileData: {
                error: 'errorMessage',
              },
              fileName: 'file2.jpg',
              number: 2,
              defaultSortingNumber: 2,
            },
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file3.jpg',
              number: 3,
              defaultSortingNumber: 3,
              description: 'JPG file',
            },
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file4.csv',
              number: 4,
              defaultSortingNumber: 4,
              description: 'CSV file',
            },
            {
              status: 'uploading',
              fileData: {
                error: '',
              },
              fileName: 'file5.csv',
              number: 5,
              defaultSortingNumber: 5,
              progress: 50,
            },
          ],
          dragAndDropCard: {
            onPress: onOpenFileSelector,
            helperText: 'Helper text',
            errorMessage: 'Error message',
          },
          ...multipleDefaultContext,
        }}
        helpFunction={onPressHelp}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>
        Multiple - Error Version (Max Count Reached)
      </Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        helperText='Error message'
        multiple
        maxFileUploadCount={3}
        context={{
          uploadedFiles: maxFileCountUploadedFiles,
          dragAndDropCard: {
            onPress: onOpenFileSelector,
            helperText: 'Helper text',
            errorMessage: 'Error message',
          },
          ...multipleDefaultContext,
        }}
        helpFunction={onPressHelp}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />
      <Text.Body weight='bold'>Multiple - Error Version (Empty)</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        error
        helperText='Upload required'
        multiple
        context={{
          uploadedFiles: [],
          dragAndDropCard: {
            onPress: onOpenFileSelector,
          },
          ...multipleDefaultContext,
        }}
        helpFunction={onPressHelp}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>
        Multiple - Label Data Pair Display Version
      </Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload variant='display' {...multipleCompleteProps} />

      <Gap size='f48' height />

      <Text.Body weight='bold'>
        Multiple - Label Data Pair Edit Version
      </Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload variant='edit' {...multipleCompleteProps} />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Multiple - Disabled Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload helperText='Helper text' multiple disabled />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Mobile Multiple Module Empty</Text.Body>
      <Gap size='f12' height />
      <Input.FileUploadMobileModule
        context={{
          uploadedFiles: [],
        }}
        fileStats={{
          uploadsCount: 0,
          totalFileCount: 0,
          errorUploadsCount: 0,
          hasError: false,
        }}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>
        Mobile Multiple Module - Error Version (Max Count Reached)
      </Text.Body>
      <Gap size='f12' height />
      <Input.FileUploadMobileModule
        context={{
          uploadedFiles: maxFileCountUploadedFiles,
          dragAndDropCard: {
            helperText: 'Helper text',
            errorMessage: 'Error message',
          },
        }}
        maxFileUploadCount={2}
        fileStats={{
          uploadsCount: 2,
          totalFileCount: 5,
          errorUploadsCount: 2,
          hasError: true,
        }}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Mobile Card States</Text.Body>
      <Gap size='f12' height />
      <Input.FileUploadDragAndDropCard
        context={{
          uploadedFiles: [],
        }}
      />
      <Gap size='f12' height />
      <Input.FileUploadDragAndDropCard
        context={{
          uploadedFiles: maxFileCountUploadedFiles,
          dragAndDropCard: {
            disabled: true,
          },
        }}
      />
      <Gap size='f12' height />
      <Input.FileUploadDragAndDropCard
        context={{
          uploadedFiles: maxFileCountUploadedFiles,
          dragAndDropCard: {
            helperText: 'Helper text',
            errorMessage: 'Error message',
          },
        }}
        maxFileUploadCount={2}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Mobile Select Source</Text.Body>
      <Gap size='f12' height />
      <Input.FileUploadMobileSelect onSelectFileSource={onSelectFileSource} />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Mobile Empty State</Text.Body>
      <Gap size='f12' height />
      <Input.FileUploadEmptyState uploadedFiles={[]} />

      <Gap size='f48' height />
    </StyledCustomComponent>
  );
}
