import React, { useLayoutEffect } from 'react';
import { Spacer, Colors, Shadow } from 'advance-components';
import styled from 'styled-components/native';
import Container from '../components/container';
import Row from '../components/row';

const Box = styled.View`
  height: 80px;
  width: 80px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export default function ShadowScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Shadow',
    });
  }, []);

  return (
    <Container>
      <Row>
        <Shadow type='bevelled'>
          <Box backgroundColor={Colors.white} />
        </Shadow>
        <Shadow type='shallowtop'>
          <Box backgroundColor={Colors.white} />
        </Shadow>
        <Shadow type='shallowbottom'>
          <Box backgroundColor={Colors.white} />
        </Shadow>
        <Shadow type='raised'>
          <Box backgroundColor={Colors.white} />
        </Shadow>
      </Row>
      <Spacer size='f24' height />
      <Row>
        <Shadow type='bevelled'>
          <Box backgroundColor={Colors.neutral} />
        </Shadow>
        <Shadow type='shallowtop'>
          <Box backgroundColor={Colors.neutral} />
        </Shadow>
        <Shadow type='shallowbottom'>
          <Box backgroundColor={Colors.neutral} />
        </Shadow>
        <Shadow type='raised'>
          <Box backgroundColor={Colors.neutral} />
        </Shadow>
      </Row>
    </Container>
  );
}
