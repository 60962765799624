import React, { useLayoutEffect } from 'react';
import { Spacer, BreakdownRow, Status } from 'advance-components';
import Container from '../components/container';

export default function BreakdownRowScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Breakdown Row',
    });
  }, []);

  return (
    <Container>
      <BreakdownRow label='Breakdown Row Status'>
        <Status type='success'>Success</Status>
      </BreakdownRow>
      <Spacer size='f24' height />
      <BreakdownRow
        type='default'
        label='Breakdown Row Default'
        amount={1000}
      />
      <Spacer size='f24' height />
      <BreakdownRow
        type='emphasis'
        label='Breakdown Row Emphasis'
        amount={1000}
      />
      <Spacer size='f24' height />
      <BreakdownRow
        type='discount'
        label='Breakdown Row Discount'
        amount={1296}
      />
      <Spacer size='f24' height />
      <BreakdownRow
        type='discounted'
        label='Breakdown Row Discounted'
        amount={2953}
        discountedAmount={1945}
      />
      <Spacer size='f24' height />
      <BreakdownRow
        type='collapsed'
        label='Breakdown Row Collapsed'
        amount={1000}
      />
      <Spacer size='f24' height />
      <BreakdownRow
        type='expanded'
        label='Breakdown Row Expanded'
        amount={1000}
      />
      <Spacer size='f24' height />
      <BreakdownRow type='total' label='Breakdown Row Total' amount={1000} />
      <Spacer size='f24' height />
      <BreakdownRow type='fees' label='Breakdown Row Fees' amount={1000} />
    </Container>
  );
}
