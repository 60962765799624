import React, { useLayoutEffect } from 'react';
import { Spacer, Text } from 'advance-components';
import Container from '../components/container';

export default function TitleBarScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Title Bar',
    });
  }, []);

  return (
    <Container>
      <Text.TitleBase
        onPress={() => {
          navigation.push('TitleBarDefaultScreen');
        }}
      >
        View Title Bar Default
      </Text.TitleBase>
      <Spacer size='f24' height />
      <Text.TitleBase
        onPress={() => {
          navigation.push('TitleBarDarkScreen');
        }}
      >
        View Title Bar Dark
      </Text.TitleBase>
    </Container>
  );
}
