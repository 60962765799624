import React, { useLayoutEffect } from 'react';
import { SectionTitle, Spacer } from 'advance-components';
import Container from '../components/container';

export default function ButtonScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Section Title',
    });
  }, []);

  return (
    <Container>
      <SectionTitle name='Sample Section 1'>Sample Button 1</SectionTitle>
      <Spacer size='f12' height />
      <SectionTitle name='Sample Section 2'>Sample Button 2</SectionTitle>
      <Spacer size='f12' height />
      <SectionTitle name='Payments Due'>View All</SectionTitle>
      <Spacer size='f12' height />
      <SectionTitle name='File' icon='upload-file'>
        Upload
      </SectionTitle>
    </Container>
  );
}
