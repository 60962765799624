import React, { useLayoutEffect } from 'react';
import { Spacer, Pill, DividerLine, Text } from 'advance-components';
import Container from '../components/container';

export default function PillScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Pill',
    });
  }, []);

  return (
    <Container>
      <Text.BodyBase>Pill Text</Text.BodyBase>
      <Spacer size='f16' height />
      <Pill type='neutral'>Neutral</Pill>
      <Spacer size='f24' height />
      <Pill type='information'>Information</Pill>
      <Spacer size='f24' height />
      <Pill type='success'>Success</Pill>
      <Spacer size='f24' height />
      <Pill type='warning'>Warning</Pill>
      <Spacer size='f24' height />
      <Pill type='failed'>Failed</Pill>
      <Spacer size='f16' height />
      <DividerLine />
      <Spacer size='f16' height />
      <Text.BodyBase>Pill Text with Icon</Text.BodyBase>
      <Spacer size='f16' height />
      <Pill type='neutral' icon='info'>
        Neutral
      </Pill>
      <Spacer size='f24' height />
      <Pill type='information' icon='info'>
        Information
      </Pill>
      <Spacer size='f24' height />
      <Pill type='success' icon='info'>
        Success
      </Pill>
      <Spacer size='f24' height />
      <Pill type='warning' icon='info'>
        Warning
      </Pill>
      <Spacer size='f24' height />
      <Pill type='failed' icon='info'>
        Failed
      </Pill>
    </Container>
  );
}
