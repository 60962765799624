import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import { Gap, Toggle, Colors } from 'advance-components';
import useMobile from 'advance-components/src/hooks/use-mobile';
import Container from '../components/container';

const StyledGroup = styled.View`
  background-color: ${Colors.neutral100};
  ${(props) => !props.mobile && { maxWidth: 300 }};
  margin: 20px;
  padding: 20px;
`;

export default function ToggleScreen({ navigation }) {
  const mobile = useMobile();

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Toggle',
    });
  }, []);

  return (
    <Container>
      <StyledGroup mobile={mobile}>
        <Toggle onToggle={() => {}} />
        <Gap size='f24' height />
        <Toggle active onToggle={() => {}} />
        <Gap size='f24' height />
        <Toggle disabled />
      </StyledGroup>
    </Container>
  );
}
