import React, { useLayoutEffect } from 'react';
import {
  Spacer,
  Icon,
  Text,
  DividerLine,
  SpecialCard,
} from 'advance-components';
import Container from '../components/container';
import logo from '../../assets/aa-logo.png';

export default function SpecialCardScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Special Card',
    });
  }, []);

  return (
    <Container>
      <Text.BodyBase>Logo Card</Text.BodyBase>
      <Spacer size='f24' height />
      <SpecialCard.Logo
        name='AirAsia'
        imgSrc={logo}
        description='Supplementary Text'
      />
      <Spacer size='f24' height />
      <SpecialCard.Logo
        name='AirAsia'
        imgSrc={logo}
        description='Supplementary Text'
        type='favorite'
      />
      <Spacer size='f24' height />
      <DividerLine />
      <Spacer size='f24' height />
      <Text.BodyBase>Reference Card</Text.BodyBase>
      <Spacer size='f24' height />
      <SpecialCard.Reference referenceNumber='LGBUVD51' />
      <Spacer size='f24' height />
      <SpecialCard.Reference type='review' referenceNumber='LGBUVD51' />
      <Spacer size='f24' height />
      <DividerLine />
      <Spacer size='f24' height />
      <Text.BodyBase>Reason Card</Text.BodyBase>
      <Spacer size='f24' height />
      <SpecialCard.Reason
        name='Medicine'
        iconName='Medicine'
        iconFamily={Icon.AdvanceLineIcon}
      />
    </Container>
  );
}
