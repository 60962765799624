import React, { useLayoutEffect } from 'react';
import { KycRequirements, Spacer } from 'advance-components';
import Container from '../components/container';

export default function KycRequirementsScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Kyc Requirements',
    });
  }, []);

  return (
    <Container>
      <KycRequirements />
      <Spacer size='f20' height />
      <KycRequirements.GovernmentId />
      <Spacer size='f20' height />
      <KycRequirements.ProofOfEmployment />
      <Spacer size='f20' height />
      <KycRequirements.PayrollAccountDetails />
    </Container>
  );
}
