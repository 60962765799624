import React, { useLayoutEffect } from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { images } from 'advance-components';
import Container from '../components/container';

const RendererContainer = styled.View`
  margin: 30px;
`;

const StyledImage = styled.Image`
  height: 60px;
  width: 280px;
`;

export default function ImagesScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Images',
    });
  }, []);

  return (
    <Container>
      {Object.keys(images).map((key, index) => (
        <RendererContainer key={index}>
          {Platform.select({
            web: <StyledImage source={{ uri: images[key] }} />,
            default: <StyledImage source={images[key]} />,
          })}
        </RendererContainer>
      ))}
    </Container>
  );
}
