import React, { useLayoutEffect } from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { Text, Colors, Gap, TabGroup, DividerLine } from 'advance-components';
import Container from '../components/container';
import useMobile from 'advance-components/src/hooks/use-mobile';

export default function TabGroupScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Tab Group',
    });
  }, []);

  const mobile = useMobile();
  const getWidth = Platform.select({
    web: () => (mobile ? '100%' : 400),
    default: () => 'max-content',
  });

  const StyledColumn = styled.View`
    flex-direction: column;
    gap: 24;
    ${(props) =>
      props.custom && {
        backgroundColor: Colors.neutral100,
        padding: 16,
        width: getWidth(),
        alignSelf: 'start',
      }};
  `;

  return (
    <Container>
      <Text.Label>
        Note: individual tabs are displayed in{' '}
        <Text.Label
          color={Colors.primary40}
          onPress={() => navigation.navigate('ButtonScreen')}
          textDecorationLine='underline'
        >
          Button {'>'} Tab Buttons
        </Text.Label>
      </Text.Label>
      <Gap size='f24' height />
      <DividerLine />
      <Gap size='f24' height />
      <StyledColumn custom>
        <TabGroup
          tabs={[
            { label: 'Page 1', onPress: () => {} },
            { label: 'Page 2', onPress: () => {} },
          ]}
        />
        <TabGroup
          tabs={[
            { label: 'Page 1', onPress: () => {} },
            { label: 'Page 2', onPress: () => {} },
            { label: 'Page 3', onPress: () => {} },
          ]}
        />
        <TabGroup
          tabs={[
            { label: 'All', onPress: () => {} },
            { label: 'Overdue', onPress: () => {} },
            { label: 'Today', onPress: () => {} },
            { label: 'Soon', onPress: () => {} },
          ]}
        />
      </StyledColumn>
    </Container>
  );
}
