import React, { useLayoutEffect } from 'react';
import { Spacer, Lozenge } from 'advance-components';
import Container from '../components/container';

export default function LozengeScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Lozenge',
    });
  }, []);

  return (
    <Container>
      {/* Neutral */}
      <Lozenge type='neutral' variant='ghost'>
        Neutral
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='neutral' variant='ghost' icon='information-solid'>
        Neutral
      </Lozenge>
      <Spacer size='f24' height />
      <Lozenge type='neutral' variant='tinted'>
        Neutral
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='neutral' variant='tinted' icon='information-solid'>
        Neutral
      </Lozenge>
      <Spacer size='f24' height />
      <Lozenge type='neutral' variant='filled'>
        Neutral
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='neutral' variant='filled' icon='information-solid'>
        Neutral
      </Lozenge>
      <Spacer size='f24' height />

      {/* Informative */}
      <Lozenge type='informative' variant='ghost'>
        Informative
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='informative' variant='ghost' icon='information-solid'>
        Informative
      </Lozenge>
      <Spacer size='f24' height />
      <Lozenge type='informative' variant='tinted'>
        Informative
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='informative' variant='tinted' icon='information-solid'>
        Informative
      </Lozenge>
      <Spacer size='f24' height />
      <Lozenge type='informative' variant='filled'>
        Informative
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='informative' variant='filled' icon='information-solid'>
        Informative
      </Lozenge>
      <Spacer size='f24' height />

      {/* Positive */}
      <Lozenge type='positive' variant='ghost'>
        Positive
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='positive' variant='ghost' icon='correct-solid'>
        Positive
      </Lozenge>
      <Spacer size='f24' height />
      <Lozenge type='positive' variant='tinted'>
        Positive
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='positive' variant='tinted' icon='correct-solid'>
        Positive
      </Lozenge>
      <Spacer size='f24' height />
      <Lozenge type='positive' variant='filled'>
        Positive
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='positive' variant='filled' icon='correct-solid'>
        Positive
      </Lozenge>
      <Spacer size='f24' height />

      {/* Caution */}
      <Lozenge type='caution' variant='ghost'>
        Caution
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='caution' variant='ghost' icon='error-solid'>
        Caution
      </Lozenge>
      <Spacer size='f24' height />
      <Lozenge type='caution' variant='tinted'>
        Caution
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='caution' variant='tinted' icon='error-solid'>
        Caution
      </Lozenge>
      <Spacer size='f24' height />
      <Lozenge type='caution' variant='filled'>
        Caution
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='caution' variant='filled' icon='error-solid'>
        Caution
      </Lozenge>
      <Spacer size='f24' height />

      {/* Negative */}
      <Lozenge type='negative' variant='ghost'>
        Negative
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='negative' variant='ghost' icon='warning-solid'>
        Negative
      </Lozenge>
      <Spacer size='f24' height />
      <Lozenge type='negative' variant='tinted'>
        Negative
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='negative' variant='tinted' icon='warning-solid'>
        Negative
      </Lozenge>
      <Spacer size='f24' height />
      <Lozenge type='negative' variant='filled'>
        Negative
      </Lozenge>
      <Spacer size='f12' height />
      <Lozenge type='negative' variant='filled' icon='warning-solid'>
        Negative
      </Lozenge>
      <Spacer size='f24' height />
    </Container>
  );
}
