import styled from 'styled-components/native';
import { Colors } from 'advance-components';

const Container = styled.ScrollView.attrs((props) => ({
  contentContainerStyle: {
    padding: 10,
    backgroundColor: props.backgroundColor ?? Colors.gray50,
  },
}))``;

export default Container;
