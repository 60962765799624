import React, { useLayoutEffect } from 'react';
import { Spacer, PageControl } from 'advance-components';
import Container from '../components/container';

export default function PageControlScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Page Control',
    });
  }, []);

  return (
    <Container>
      <Spacer size='d48' height />
      <PageControl />
      <Spacer size='d96' height />
      <PageControl active={1} stepPress={() => {}} />
      <Spacer size='d96' height />
      <PageControl active={2} />
      <Spacer size='d48' height />
    </Container>
  );
}
