import React, { useLayoutEffect } from 'react';
import { Spacer, BreakdownRow } from 'advance-components';
import Container from '../components/container';

export default function BreakdownRowScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Breakdown Row',
    });
  }, []);

  return (
    <Container>
      <BreakdownRow type='bold' label='Breakdown Row Header' amount={1000} />
      <Spacer size='f24' height />
      <BreakdownRow type='default' label='Breakdown Row Header' amount={1000} />
      <Spacer size='f24' height />
      <BreakdownRow
        type='bold'
        label='Breakdown Row Header'
        amount={0}
        subheaderLabel='Label sub header'
        subheaderValue='Value sub header'
      />
      <Spacer size='f24' height />
      <BreakdownRow
        type='default'
        label='Breakdown Row Header'
        amount={0}
        subheaderLabel='Label sub header'
        subheaderValue='Value sub header'
      />
    </Container>
  );
}
