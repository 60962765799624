import React, { useLayoutEffect } from 'react';
import { Spacer, Status } from 'advance-components';
import Container from '../components/container';

export default function StatusScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Status',
    });
  }, []);

  return (
    <Container>
      <Status type='information'>Information</Status>
      <Spacer size='f24' height />
      <Status type='success'>Success</Status>
      <Spacer size='f24' height />
      <Status type='error'>Error</Status>
      <Spacer size='f24' height />
      <Status type='warning'>Warning</Status>
      <Spacer size='f24' height />
      <Status type='neutral'>Neutral</Status>
    </Container>
  );
}
