import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import { Platform } from 'react-native';
import { Spacer, Voucher } from 'advance-components';
import Container from '../components/container';

const maxWidth = Platform.select({
  web: '500px',
  default: '100%',
});

const InnerContainer = styled.View`
  justify-content: center;
  max-width: ${maxWidth};
`;

const adjustDate = (initialDate, days, adjustment) => {
  const date = new Date(initialDate);
  let newDate;

  if (adjustment === 'add') {
    newDate = date.getDate() + days;
  } else {
    newDate = date.getDate() - days;
  }

  date.setDate(newDate);

  return date;
};

export default function VoucherScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Voucher',
    });
  }, []);

  const date1 = new Date();
  const date2 = adjustDate(date1, 30, 'add');
  const date3 = adjustDate(date1, 35, 'subtract');
  const date4 = adjustDate(date3, 10, 'add');
  const date5 = adjustDate(date2, 7, 'add');

  return (
    <Container>
      <InnerContainer>
        <Spacer size='f24' height />
        <Voucher
          title='Lorem ipsum dolor sit amet'
          date={date1}
          useCode={() => {}}
          onPress={() => {}}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum dolor
          sit amet
        </Voucher>
        <Spacer size='f24' height />

        <Voucher
          title='Lorem ipsum dolor sit amet'
          date={date2}
          useCode={() => {}}
          onPress={() => {}}
          isSelected
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum dolor
          sit amet
        </Voucher>
        <Spacer size='f24' height />

        <Voucher
          title='Lorem ipsum dolor sit amet'
          date={date3}
          useCode={() => {}}
          onPress={() => {}}
          type='expired'
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum dolor
          sit amet
        </Voucher>
        <Spacer size='f24' height />

        <Voucher
          title='Lorem ipsum dolor sit amet'
          date={date4}
          useCode={() => {}}
          onPress={() => {}}
          type='applied'
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum dolor
          sit amet
        </Voucher>
        <Spacer size='f24' height />

        <Voucher
          title='Lorem ipsum dolor sit amet'
          date={date5}
          useCode={() => {}}
          onPress={() => {}}
          type='not-applicable'
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum dolor
          sit amet
        </Voucher>
        <Spacer size='f24' height />
      </InnerContainer>
    </Container>
  );
}
